import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';
import { EditorModule } from 'primeng/editor';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';

import {
  PanelModule,
  AccordionModule,
  ButtonModule,
  RadioButtonModule,
  CardModule
} from 'primeng/primeng';
@NgModule({
  imports: [
    CommonModule, MessagesModule, MessageModule,
    PanelModule, EditorModule,
    KeyFilterModule,
    InputSwitchModule,
    SidebarModule,
    TooltipModule,
    AccordionModule,
    ButtonModule,
    RadioButtonModule,
    CardModule,
    TableModule,
    InputTextModule,
    PaginatorModule,
    CheckboxModule,
    CalendarModule,
    InputMaskModule,
    AutoCompleteModule,
    DropdownModule,
    FieldsetModule,
    TabViewModule,
    BreadcrumbModule,
    ScrollPanelModule,
    DialogModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ChartModule
  ],
  exports: [CommonModule,
    InputMaskModule,
    PanelModule, MessagesModule, MessageModule, TooltipModule,
    AccordionModule, EditorModule,
    KeyFilterModule,
    InputSwitchModule,
    SidebarModule,
    ButtonModule,
    RadioButtonModule,
    CardModule,
    TableModule,
    InputTextModule,
    PaginatorModule,
    CheckboxModule,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    FieldsetModule,
    TabViewModule,
    BreadcrumbModule,
    ScrollPanelModule,
    DialogModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ChartModule
  ],
  declarations: []
})
export class AppPrimeNGModule { }
