export class LockUsers {
	constructor(
		public id: number = null,
		public name: string = '',
		public newName: string = null,
		public phone: string = '',
		public email: string = '',
		public company: string = '',
		public generateArbitraryCode: number = null,
		public updateArbitraryCode: number = 0,
    public arbitraryKeypadCode: number = null,
    public countOfUsers: number = 0,
    public addUsersStartingRange: number = 0
	) { }
}
