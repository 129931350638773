export class LockUpdateRequest {
  constructor(
    public serialNumber: string = '',
    public location: string = null,
    public lockType: string = '',
    public customer: string = null,
    public notes: string = null,
    public generateRandomSeedCode: number = 0,
    public newSeedCode: string = null,
    public Interface: number = 0,
    public cloneLockSerialNumber: string = null
  ) { }
}
