import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DataService } from '../shared/dataService';
import * as AppConstants from '../common/app.constants';
import { AppRoles } from '../common/app.roles';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
	loginUser: string;
	userRole: string;
	subscriptions: Array<Subscription> = [];
	manageLocksCardSubTitle: string;

	constructor(private router: Router, private dataService: DataService, private spinner: NgxSpinnerService,
		private appLabels: AppConstants.AppLabels, private appRoles: AppRoles) {
		this.setUserInfo();
		this.manageLocksCardSubTitle = this.userRole.toUpperCase() == appRoles.globalAdmin.toUpperCase()
			? appLabels.manageLocksCardSubTitleForAdmin
			: appLabels.manageLocksCardSubTitle;
	}

	setUserInfo() {
		const token = this.dataService.getAccessToken();
		const decodedToken = this.dataService.getDecodedAccessToken(token);
		if (decodedToken) {
			this.loginUser = decodedToken.unique_name;
			this.userRole = decodedToken.Role;
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.dataService.checkIfAdminLoggedAsOwner().subscribe((isAdminAsOwner: boolean) => {
				if (isAdminAsOwner) {
					this.setUserInfo();
				}
			})
		);
	}

	navUser() {
		this.router.navigate(['user']);
	}

	navUnits() {
		this.router.navigate(['units']);
	}

	navAssignedUnits() {
		const navExtras: NavigationExtras = {
			queryParams: {
				displayAssignedLocks: true
			}
		};
		this.router.navigate(['units'], {
			state: { locks: navExtras }
		});
	}

	navCodes() {
		this.router.navigate(['codes']);
	}

	navGroups() {
		this.router.navigate(['groups']);
	}

	navAudits() {
		this.router.navigate(['audits']);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
