import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import { NgxSpinnerService } from 'ngx-spinner';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Repository } from '../models/repository';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
	LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';
	LOCALSTORAGE_AUTH = 'Authorization';

	constructor(private router: Router, private http: HttpClient, private spinner: NgxSpinnerService,
		private toastr: ToastrService, private repo: Repository) { }

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		let authReq = req;
		const accessToken = this.getAccessToken();
		if (accessToken != null) {
			authReq = req.clone({
				headers: req.headers.append(this.LOCALSTORAGE_AUTH, 'Bearer ' + accessToken).
					append('Content-Type', 'application/json')
			});
		}
		return next.handle(authReq).do(
			event => {
				if (event instanceof HttpResponse) {
					const isSessionValid: boolean = this.isValidSession();
					if (!isSessionValid) {
						this.errorHandler('Session timedout');
						this.logout();
					}
				}
			},
			err => {
				if (err instanceof HttpErrorResponse) {
					const isSessionValid: boolean = this.isValidSession();
					if (!isSessionValid) {
						this.logout();
					}
					this.spinner.hide();
					this.toastr.error(err.statusText, 'Error', {
						positionClass: 'toast-top-center',
						timeOut: 5000
					});
					// return Observable.throw(err);
				}
			},
			// Complete event
			() => {
			}
		);
	}

	errorHandler(error): void {
		if (!this.isValidSession()) {
			// Added condition to igone multiple service calls when session timedout.
			// if(!this.errorHandled){
			//  this.errorHandled = true;

			// this.toastr.error("Session is invalid or has timed out",'Error',{
			//       positionClass : 'toast-top-center',
			//       timeOut: 7000
			// });

			// }
		} else {
			if (error && error.error && error.error.message) {
				this.toastr.error(error.error.message, 'Error', {
					positionClass: 'toast-top-center',
					timeOut: 20000
				});
			} else if (error && error.message) {
				this.toastr.error(error.message, 'Error', {
					positionClass: 'toast-top-center',
					timeOut: 20000
				});
			} else {
				this.toastr.error('Something went wrong', 'Error', {
					positionClass: 'toast-top-center',
					timeOut: 5000
				});
			}
		}
		this.spinner.hide();
	}

	isValidSession(): boolean {
		const accessToken = this.getAccessToken();
		const tokenInfo = this.getDecodedAccessToken(accessToken);
		if (tokenInfo != null && tokenInfo != undefined) {
			const current_time = Date.now() / 1000; // time in seconds
			const expiryDate = tokenInfo.exp;
			const timeDiffMins = (expiryDate - current_time) / 60;
			if ((expiryDate > current_time)) {
				// if (timeDiffMins >= 0 && timeDiffMins < 16) {
				//     console.log("time diff::" + timeDiffMins + " mins..");
				//     this.refreshToken().subscribe((res) => {
				//         let tokenInfo = res;
				//         this.setToken(tokenInfo != undefined ? tokenInfo.token : '');
				//     });
				//     return true;
				// } else {
				//     console.log("time diff::" + timeDiffMins + " mins..");
				//     return true;
				// }
				return true;
			} else {
				console.log('token expired...');
				return false;
			}
		} else {
			return false;
		}
	}

	logout() {
		// localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
		// this.router.navigate(['login']);
		// this.spinner.hide();
		this.repo.logout();
	}

	getAccessToken() {
		return localStorage.getItem(this.LOCALSTORAGE_AUTH_TOKEN);
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
}
