import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent {
	isValidSession = false;

  constructor(private router: Router) {}

	ngOnInit() {
		const session = localStorage.getItem('authenticationToken');
		if (session) {
			this.isValidSession = true;
		} else {
			this.isValidSession = false;
    }
    document.getElementById('copyrightYear').appendChild(document.createTextNode(new Date().getFullYear().toString()))
  }

  navUnits() {
    this.router.navigate(['units']);
  }
}
