// import { NumberSymbol } from "@angular/common";

export class LockUserAccess {
	accessRecords: LockUsersDetails[];
}

export class LockUsersDetails {
	serialNumber: string;

	lockSerialNumber: string;
	lockUserName: string;
	keypadCode: string;
	codeTypeDescription: string;
	lockLocation: string;
	lockUserId: number;

	lockUserGroup: string;

	added: string;
	deleted: string;
	disabled: string;
}
