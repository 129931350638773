import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as AppConstants from './app.constants';

@Injectable({
	providedIn: 'root'
})
export class AppNotification {
	/**
	 * Creates an instance of ErrorNotification.
	 * @param toastr
	 */
	constructor(private toastr: ToastrService) { }

	/**
	 * Displays success notification.
	 * @param message success message to be displayed.
	 * @param title Title of the notification.
	 * */
	success(message: string, title = 'Success') {
		this.toastr.success(message, title, {
			positionClass: AppConstants.Toastr.successPosition,
			timeOut: AppConstants.Toastr.successTimeout
		});
	}

	/**
	 * Displays error notification.
	 * @param message Error message to be displayed.
	 * @param title Title of the notification.
	 * @param timeOut Toast time to live in milliseconds.
	 * */
	error(message: string, title = 'Someting went wrong', timeOut = AppConstants.Toastr.errorTimeout) {
		this.toastr.error(message, title, {
			positionClass: AppConstants.Toastr.errorPosition,
			timeOut: timeOut
		});
	}

	/**
	 * Displays Information notification.
	 * @param message Information message to be displayed.
	 * @param title Title of the notification.
	 */
	info(message: string, title = 'Information') {
		this.toastr.info(message, title, {
			positionClass: AppConstants.Toastr.infoPosition,
			timeOut: AppConstants.Toastr.infoTimeout
		});
	}

	/**
	 * Displays warning notification.
	 * @param message Information message to be displayed.
	 * @param title Title of the notification.
	 */
	warning(message: string, title = 'Warning') {
		this.toastr.warning(message, title, {
			positionClass: AppConstants.Toastr.warningPosition,
			timeOut: AppConstants.Toastr.warningTimeout
		});
	}
}
