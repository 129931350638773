import { Component, HostListener } from '@angular/core';
import { DataService } from '../shared/dataService';
import { Router } from '@angular/router';
import { UserLocksModel } from '../models/UserLocksModel.model';
import { Repository } from '../models/repository';
import * as $ from 'jquery';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuditResponseRecord } from '../models/AuditResponseRecord.model';
import * as AppConstants from '../common/app.constants';
import { FilterUtils } from 'primeng/components/utils/filterutils';
import { AppNotification } from '../common/app.notification';
import * as AppHelper from '../common/app.helper';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent {
  tblDheight = AppConstants.Generic.tableHeight;
  userRole: string;

  /** Gets or sets the number of rows per page to be displayed. */
  rowsPerPage = AppConstants.Generic.rowsPerPage;

  /** Gets or sets the title for the Units page.*/
  pageTitle = AppConstants.PageTitle.audits;

  viewUserLocks = false;
  showActivateLockDialog = false;
  showDownloadAuditLogsDialog = false;
  viewAudit = false;
  id = '';
  required = '';
  submitted = '';
  cols: any[];
  auditCols: any[];
  lockSerialNumbers = [];
  lockLocations = [];
  lockCustomers = [];
  lockInterface = [];
  lockStatuses = [];
  lockAuditEvents = [];

  fileFormats = [
    { label: this.appLabels.fileFormatCsv, value: this.appLabels.fileFormatCsv },
    { label: this.appLabels.fileFormatPdf, value: this.appLabels.fileFormatPdf }
  ];

  timeZone_dropDownValues = [
    { label: '(UTC-12:00) International Date Line West', value: 'Dateline Standard Time' },
    { label: '(UTC-11:00) Coordinated Universal Time-11', value: 'UTC-11' },
    { label: '(UTC-10:00) Aleutian Islands', value: 'Aleutian Standard Time' },
    { label: '(UTC-10:00) Hawaii', value: 'Hawaiian Standard Time' },
    { label: '(UTC-09:30) Marquesas Islands', value: 'Marquesas Standard Time' },
    { label: '(UTC-09:00) Alaska', value: 'Alaskan Standard Time' },
    { label: '(UTC-09:00) Coordinated Universal Time-09', value: 'UTC-09' },
    { label: '(UTC-08:00) Baja California', value: 'Pacific Standard Time (Mexico)' },
    { label: '(UTC-08:00) Coordinated Universal Time-08', value: 'UTC-08' },
    { label: '(UTC-08:00) Pacific Time (US & Canada)', value: 'Pacific Standard Time' },
    { label: '(UTC-07:00) Arizona', value: 'US Mountain Standard Time' },
    { label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan', value: 'Mountain Standard Time (Mexico)' },
    { label: '(UTC-07:00) Mountain Time (US & Canada)', value: 'Mountain Standard Time' },
    { label: '(UTC-07:00) Yukon', value: 'Yukon Standard Time' },
    { label: '(UTC-06:00) Central America', value: 'Central America Standard Time' },
    { label: '(UTC-06:00) Central Time (US & Canada)', value: 'Central Standard Time' },
    { label: '(UTC-06:00) Easter Island', value: 'Easter Island Standard Time' },
    { label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey', value: 'Central Standard Time (Mexico)' },
    { label: '(UTC-06:00) Saskatchewan', value: 'Canada Central Standard Time' },
    { label: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco', value: 'SA Pacific Standard Time' },
    { label: '(UTC-05:00) Chetumal', value: 'Eastern Standard Time (Mexico)' },
    { label: '(UTC-05:00) Eastern Time (US & Canada)', value: 'Eastern Standard Time' },
    { label: '(UTC-05:00) Haiti', value: 'Haiti Standard Time' },
    { label: '(UTC-05:00) Havana', value: 'Cuba Standard Time' },
    { label: '(UTC-05:00) Indiana (East)', value: 'US Eastern Standard Time' },
    { label: '(UTC-05:00) Turks and Caicos', value: 'Turks and Caicos Standard Time' },
    { label: '(UTC-04:00) Asuncion', value: 'Paraguay Standard Time' },
    { label: '(UTC-04:00) Atlantic Time (Canada)', value: 'Atlantic Standard Time' },
    { label: '(UTC-04:00) Caracas', value: 'Venezuela Standard Time' },
    { label: '(UTC-04:00) Cuiaba', value: 'Central Brazilian Standard Time' },
    { label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', value: 'SA Western Standard Time' },
    { label: '(UTC-04:00) Santiago', value: 'Pacific SA Standard Time' },
    { label: '(UTC-03:30) Newfoundland', value: 'Newfoundland Standard Time' },
    { label: '(UTC-03:00) Araguaina', value: 'Tocantins Standard Time' },
    { label: '(UTC-03:00) Brasilia', value: 'E. South America Standard Time' },
    { label: '(UTC-03:00) Cayenne, Fortaleza', value: 'SA Eastern Standard Time' },
    { label: '(UTC-03:00) City of Buenos Aires', value: 'Argentina Standard Time' },
    { label: '(UTC-03:00) Greenland', value: 'Greenland Standard Time' },
    { label: '(UTC-03:00) Montevideo', value: 'Montevideo Standard Time' },
    { label: '(UTC-03:00) Punta Arenas', value: 'Magallanes Standard Time' },
    { label: '(UTC-03:00) Saint Pierre and Miquelon', value: 'Saint Pierre Standard Time' },
    { label: '(UTC-03:00) Salvador', value: 'Bahia Standard Time' },
    { label: '(UTC-02:00) Coordinated Universal Time-02', value: 'UTC-02' },
    { label: '(UTC-02:00) Mid-Atlantic - Old', value: 'Mid-Atlantic Standard Time' },
    { label: '(UTC-01:00) Azores', value: 'Azores Standard Time' },
    { label: '(UTC-01:00) Cabo Verde Is.', value: 'Cabo Verde Standard Time' },
    { label: '(UTC) Coordinated Universal Time', value: 'Coordinated Universal Time' },
    { label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London', value: 'GMT Standard Time' },
    { label: '(UTC+00:00) Monrovia, Reykjavik', value: 'Greenwich Standard Time' },
    { label: '(UTC+00:00) Sao Tome', value: 'Sao Tome Standard Time' },
    { label: '(UTC+01:00) Casablanca', value: 'Morocco Standard Time' },
    { label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'W. Europe Standard Time' },
    { label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'Central Europe Standard Time' },
    { label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Romance Standard Time' },
    { label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Central European Standard Time' },
    { label: '(UTC+01:00) West Central Africa', value: 'W. Central Africa Standard Time' },
    { label: '(UTC+02:00) Amman', value: 'Jordan Standard Time' },
    { label: '(UTC+02:00) Athens, Bucharest', value: 'GTB Standard Time' },
    { label: '(UTC+02:00) Beirut', value: 'Middle East Standard Time' },
    { label: '(UTC+02:00) Cairo', value: 'Egypt Standard Time' },
    { label: '(UTC+02:00) Chisinau', value: 'E. Europe Standard Time' },
    { label: '(UTC+02:00) Damascus', value: 'Syria Standard Time' },
    { label: '(UTC+02:00) Gaza, Hebron', value: 'West Bank Gaza Standard Time' },
    { label: '(UTC+02:00) Harare, Pretoria', value: 'South Africa Standard Time' },
    { label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'FLE Standard Time' },
    { label: '(UTC+02:00) Jerusalem', value: 'Jerusalem Standard Time' },
    { label: '(UTC+02:00) Kaliningrad', value: 'Russia TZ 1 Standard Time' },
    { label: '(UTC+02:00) Khartoum', value: 'Sudan Standard Time' },
    { label: '(UTC+02:00) Tripoli', value: 'Libya Standard Time' },
    { label: '(UTC+02:00) Windhoek', value: 'Namibia Standard Time' },
    { label: '(UTC+03:00) Baghdad', value: 'Arabic Standard Time' },
    { label: '(UTC+03:00) Istanbul', value: 'Turkey Standard Time' },
    { label: '(UTC+03:00) Kuwait, Riyadh', value: 'Arab Standard Time' },
    { label: '(UTC+03:00) Minsk', value: 'Belarus Standard Time' },
    { label: '(UTC+03:00) Moscow, St. Petersburg', value: 'Russia TZ 2 Standard Time' },
    { label: '(UTC+03:00) Nairobi', value: 'E. Africa Standard Time' },
    { label: '(UTC+03:30) Tehran', value: 'Iran Standard Time' },
    { label: '(UTC+04:00) Abu Dhabi, Muscat', value: 'Arabian Standard Time' },
    { label: '(UTC+04:00) Astrakhan, Ulyanovsk', value: 'Astrakhan Standard Time' },
    { label: '(UTC+04:00) Baku', value: 'Azerbaijan Standard Time' },
    { label: '(UTC+04:00) Izhevsk, Samara', value: 'Russia TZ 3 Standard Time' },
    { label: '(UTC+04:00) Port Louis', value: 'Mauritius Standard Time' },
    { label: '(UTC+04:00) Saratov', value: 'Saratov Standard Time' },
    { label: '(UTC+04:00) Tbilisi', value: 'Georgian Standard Time' },
    { label: '(UTC+04:00) Volgograd', value: 'Volgograd Standard Time' },
    { label: '(UTC+04:00) Yerevan', value: 'Caucasus Standard Time' },
    { label: '(UTC+04:30) Kabul', value: 'Afghanistan Standard Time' },
    { label: '(UTC+05:00) Ashgabat, Tashkent', value: 'West Asia Standard Time' },
    { label: '(UTC+05:00) Ekaterinburg', value: 'Russia TZ 4 Standard Time' },
    { label: '(UTC+05:00) Islamabad, Karachi', value: 'Pakistan Standard Time' },
    { label: '(UTC+05:00) Qyzylorda', value: 'Qyzylorda Standard Time' },
    { label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'India Standard Time' },
    { label: '(UTC+05:30) Sri Jayawardenepura', value: 'Sri Lanka Standard Time' },
    { label: '(UTC+05:45) Kathmandu', value: 'Nepal Standard Time' },
    { label: '(UTC+06:00) Astana', value: 'Central Asia Standard Time' },
    { label: '(UTC+06:00) Dhaka', value: 'Bangladesh Standard Time' },
    { label: '(UTC+06:00) Omsk', value: 'Omsk Standard Time' },
    { label: '(UTC+06:30) Yangon (Rangoon)', value: 'Myanmar Standard Time' },
    { label: '(UTC+07:00) Bangkok, Hanoi, Jakarta', value: 'SE Asia Standard Time' },
    { label: '(UTC+07:00) Barnaul, Gorno-Altaysk', value: 'Altai Standard Time' },
    { label: '(UTC+07:00) Hovd', value: 'W. Mongolia Standard Time' },
    { label: '(UTC+07:00) Krasnoyarsk', value: 'Russia TZ 6 Standard Time' },
    { label: '(UTC+07:00) Novosibirsk', value: 'Novosibirsk Standard Time' },
    { label: '(UTC+07:00) Tomsk', value: 'Tomsk Standard Time' },
    { label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'China Standard Time' },
    { label: '(UTC+08:00) Irkutsk', value: 'Russia TZ 7 Standard Time' },
    { label: '(UTC+08:00) Kuala Lumpur, Singapore', value: 'Malay Peninsula Standard Time' },
    { label: '(UTC+08:00) Perth', value: 'W. Australia Standard Time' },
    { label: '(UTC+08:00) Taipei', value: 'Taipei Standard Time' },
    { label: '(UTC+08:00) Ulaanbaatar', value: 'Ulaanbaatar Standard Time' },
    { label: '(UTC+08:45) Eucla', value: 'Aus Central W. Standard Time' },
    { label: '(UTC+09:00) Chita', value: 'Transbaikal Standard Time' },
    { label: '(UTC+09:00) Osaka, Sapporo, Tokyo', value: 'Tokyo Standard Time' },
    { label: '(UTC+09:00) Pyongyang', value: 'North Korea Standard Time' },
    { label: '(UTC+09:00) Seoul', value: 'Korea Standard Time' },
    { label: '(UTC+09:00) Yakutsk', value: 'Russia TZ 8 Standard Time' },
    { label: '(UTC+09:30) Adelaide', value: 'Cen. Australia Standard Time' },
    { label: '(UTC+09:30) Darwin', value: 'AUS Central Standard Time' },
    { label: '(UTC+10:00) Brisbane', value: 'E. Australia Standard Time' },
    { label: '(UTC+10:00) Canberra, Melbourne, Sydney', value: 'AUS Eastern Standard Time' },
    { label: '(UTC+10:00) Guam, Port Moresby', value: 'West Pacific Standard Time' },
    { label: '(UTC+10:00) Hobart', value: 'Tasmania Standard Time' },
    { label: '(UTC+10:00) Vladivostok', value: 'Russia TZ 9 Standard Time' },
    { label: '(UTC+10:30) Lord Howe Island', value: 'Lord Howe Standard Time' },
    { label: '(UTC+11:00) Bougainville Island', value: 'Bougainville Standard Time' },
    { label: '(UTC+11:00) Chokurdakh', value: 'Russia TZ 10 Standard Time' },
    { label: '(UTC+11:00) Magadan', value: 'Magadan Standard Time' },
    { label: '(UTC+11:00) Norfolk Island', value: 'Norfolk Standard Time' },
    { label: '(UTC+11:00) Sakhalin', value: 'Sakhalin Standard Time' },
    { label: '(UTC+11:00) Solomon Is., New Caledonia', value: 'Central Pacific Standard Time' },
    { label: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky', value: 'Russia TZ 11 Standard Time' },
    { label: '(UTC+12:00) Auckland, Wellington', value: 'New Zealand Standard Time' },
    { label: '(UTC+12:00) Coordinated Universal Time+12', value: 'UTC+12' },
    { label: '(UTC+12:00) Fiji', value: 'Fiji Standard Time' },
    { label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old', value: 'Kamchatka Standard Time' },
    { label: '(UTC+12:45) Chatham Islands', value: 'Chatham Islands Standard Time' },
    { label: '(UTC+13:00) Coordinated Universal Time+13', value: 'UTC+13' },
    { label: '(UTC+13:00) Nukualofa', value: 'Tonga Standard Time' },
    { label: '(UTC+13:00) Samoa', value: 'Samoa Standard Time' },
    { label: '(UTC+14:00) Kiritimati Island', value: 'Line Islands Standard Time' }
  ];

  searchAuditForm: FormGroup;
  preferredTimeZoneForm: FormGroup;
  searchLockAuditsForm: FormGroup;
  downloadAuditLogForm: FormGroup;

  selViewAuditRec: UserLocksModel;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.tblDheight = $(window).height() - 260 + 'px';
  }

  constructor(private data: DataService, private router: Router, private repo: Repository,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private appLabels: AppConstants.AppLabels, private appNotification: AppNotification,
    private apptimeZone: AppHelper.AppTimeZone, private appHelper: AppHelper.Helper) {
    this.viewAudit = false;
    this.selViewAuditRec = null;
    this.fetchLocks();
    this.tblDheight = $(window).height() - 260 + 'px';
    this.userRole = data.getLoginUserRole();

    this.searchAuditForm = this.formBuilder.group({
      lockUserName: [''],
      eventTypeDescription: [''],
      earliest: [''],
      latest: ['']
    });

    this.downloadAuditLogForm = this.getDownloadAuditLogForm();

    this.preferredTimeZoneForm = this.formBuilder.group({
      timeZone: [this.apptimeZone.currentTimeZoneName]
    });

    this.searchLockAuditsForm = this.formBuilder.group({
      searchText: ['']
    });

    FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
      const startDateTime = this.searchAuditForm.controls['earliest'].value;
      const endDateTime = this.searchAuditForm.controls['latest'].value;

      if (this.appHelper.isNullOrUndefinedOrWhiteSpace(startDateTime)
        && this.appHelper.isNullOrUndefinedOrWhiteSpace(endDateTime)) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      if (!this.appHelper.isNullOrUndefinedOrWhiteSpace(startDateTime)
        && !this.appHelper.isNullOrUndefinedOrWhiteSpace(endDateTime)) {
        return new Date(value) >= new Date(startDateTime) && new Date(value) <= new Date(endDateTime);
      }
      else if (!this.appHelper.isNullOrUndefinedOrWhiteSpace(startDateTime)) {
        return new Date(value) >= new Date(startDateTime);
      }
      else if (!this.appHelper.isNullOrUndefinedOrWhiteSpace(endDateTime)) {
        return new Date(value) <= new Date(endDateTime);
      }
      else {
        return true;
      }
    };

    FilterUtils['contains'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.toString().toLowerCase().includes(filter.toString().toLowerCase());
    };

    this.cols = [
      { field: 'serialNumber', header: appLabels.lockId },
      { field: 'location', header: appLabels.location },
      { field: 'lastAuditCollection', header: appLabels.audit },
      { field: 'customer', header: appLabels.customer },
      { field: 'notes', header: appLabels.notes }
    ];

    this.auditCols = [
      { field: 'dateTime', header: this.appLabels.dateTime, filterMatchMode: 'dateRangeFilter' },
      { field: 'lockUserName', header: this.appLabels.user, filterMatchMode: 'contains' },
      { field: 'eventType', header: this.appLabels.event, filterMatchMode: 'contains' },
    ];
  }

  get auditDetails(): AuditResponseRecord[] {
    let auditRecords = this.repo.auditDetails;

    //Default sorting
    //if (auditRecords)
    //  auditRecords = auditRecords.sort(function (a, b) {
    //    return new Date(a.dateTime) > new Date(b.dateTime) ? -1 : 1;
    //  });

    //auditRecords.forEach((auditDetail) => {
    //  //auditDetail.dateTime = this.getDateBasedOnUserTimeZone(auditDetail.dateTime);
    //  auditDetail.dateTime = new Date(auditDetail.dateTime).toLocaleString();
    //});
    return auditRecords;
  }

  get userLocks(): UserLocksModel[] {
    let userLocks = this.repo.locks;
    //userLocks.forEach((userLock) => {
    //  userLock.lastAuditCollection = this.getValidDateOrEmtpyString(userLock.lastAuditCollection);
    //});

    const searchTextControl = this.searchLockAuditsForm.controls['searchText'];
    const searchText = searchTextControl ? searchTextControl.value.trim().toLowerCase() : '';

    userLocks = userLocks.filter(lock =>
      searchText === ''
      || (lock.serialNumber && lock.serialNumber.toLowerCase().includes(searchText))
      || (lock.location && lock.location.toLowerCase().includes(searchText))
      || (lock.customer && lock.customer.toLowerCase().includes(searchText))
    );

    return userLocks;
  }

  get currentTimeZoneOffset(): string {
    return this.apptimeZone.currentTimeZoneOffset;
  }

  get currentTimeZoneName(): string {
    return this.apptimeZone.currentTimeZoneName;
  }

  prepareAuditFilterData() {
    let userLocks = this.repo.locks;
    this.lockSerialNumbers = [];
    this.lockLocations = [];
    this.lockCustomers = [];
    this.lockInterface = [];
    this.lockStatuses = [];
    this.lockAuditEvents = [];

    let selectedLockSerialNumbers = [];
    let selectedLockLocations = [];
    let selectedLockCustomers = [];

    if (userLocks && userLocks.length > 0) {
      userLocks.forEach((userLock) => {
        if (!selectedLockSerialNumbers.includes(userLock.serialNumber) && userLock.serialNumber && userLock.serialNumber != '') {
          this.lockSerialNumbers.push(
            { label: userLock.serialNumber, value: userLock.serialNumber }
          );
          selectedLockSerialNumbers.push(userLock.serialNumber);
        }
        if (!selectedLockLocations.includes(userLock.location) && userLock.location && userLock.location.trim() != '') {
          this.lockLocations.push(
            { label: userLock.location, value: userLock.location }
          );
          selectedLockLocations.push(userLock.location);
        }
        if (!selectedLockCustomers.includes(userLock.customer) && userLock.customer && userLock.customer.trim() != '') {
          this.lockCustomers.push(
            { label: userLock.customer, value: userLock.customer }
          );
          selectedLockCustomers.push(userLock.customer);
        }
      });
    }
    this.lockInterface.push(
      { label: this.appLabels.yes, value: 1 },
      { label: this.appLabels.no, value: 0 }
    );

    this.lockStatuses.push(
      { label: AppConstants.LockStatusDisplay.stock, value: AppConstants.LockStatus.stock },
      { label: AppConstants.LockStatusDisplay.onsite, value: AppConstants.LockStatus.onsite },
      { label: AppConstants.LockStatusDisplay.installed, value: AppConstants.LockStatus.installed },
      { label: AppConstants.LockStatusDisplay.inRepair, value: AppConstants.LockStatus.inRepair }
    );

    this.lockAuditEvents.push(
      { label: 'ACCESS ALLOWED WITH DAILY CODE', value: 'Access allowed with daily code' },
      { label: 'ACCESS ALLOWED WITH UNIT CODE', value: 'Access allowed with unit code' },
      { label: 'ACCESS ALLOWED WITH USER CODE', value: 'Access allowed with user code' },
      { label: 'ACCESS ALLOWED WITH WEEKLY CODE', value: 'Access allowed with weekly code' },
      { label: 'ACCESS DENIED CANCELLED UNIT CODE', value: 'Access denied cancelled unit code' },
      { label: 'ACCESS DENIED EXPIRED DAILY CODE', value: 'Access denied expired daily code' },
      { label: 'ACCESS DENIED EXPIRED WEEKLY CODE', value: 'Access denied expired weekly code' },
      { label: 'ACCESS DENIED UNKNOWN CODE', value: 'Access denied unknown code' },
      { label: 'ALARM ARMED', value: 'Alarm armed' },
      { label: 'ALARM PAIRED', value: 'Alarm paired' },
      { label: 'AUDIT REQUESTED', value: 'Audit Requested' },
      { label: 'BATTERY VOLTAGE MEASURED', value: 'Battery voltage measured' },
      { label: 'DAILY CODE CANCELLATION FAIL ALREADY CANCELLED', value: 'Daily code cancellation fail already cancelled' },
      { label: 'DAILY CODE CANCELLATION FAIL ALREADY EXPIRED', value: 'Daily code cancellation fail already expired' },
      { label: 'DAILY CODE CANCELLATION FAIL DOES NOT EXIST', value: 'Daily code cancellation fail does not exist' },
      { label: 'DAILY CODE LIMIT SET', value: 'Daily code limit set' },
      { label: 'DAILY CODE LIMIT SET FAIL', value: 'Daily code limit set fail' },
      { label: 'DAILY CODE SUCCESSFULLY CANCELLED', value: 'Daily code successfully cancelled' },
      { label: 'DOOR CLOSED', value: 'Door Closed' },
      { label: 'DOOR OPENED ', value: 'Door Opened ' },
      { label: 'DOOR OPENED FROM INSIDE', value: 'Door Opened from inside' },
      { label: 'LAST RESORT CODE ENTERED', value: 'Last resort code entered' },
      { label: 'LATCH CLOSED', value: 'Latch Closed' },
      { label: 'RESET OCCURRED', value: 'Reset occurred' },
      { label: 'SEED CODE CHANGED', value: 'Seed code changed' },
      { label: 'SEED CODE CURRENT VALUE', value: 'Seed code current value' },
      { label: 'TAMPER SENSOR PAIRED', value: 'Tamper sensor paired' },
      { label: 'TIME SET', value: 'Time set' },
      { label: 'UNIT CODE CANCELLATION FAIL ALREADY CANCELLED', value: 'Unit code cancellation fail already cancelled' },
      { label: 'UNIT CODE CANCELLATION FAIL ALREADY EXPIRED', value: 'Unit code cancellation fail already expired' },
      { label: 'UNIT CODE CANCELLATION FAIL DOES NOT EXIST', value: 'Unit code cancellation fail does not exist' },
      { label: 'UNIT CODE LIMIT SET', value: 'Unit code limit set' },
      { label: 'UNIT CODE LIMIT SET FAIL', value: 'Unit code limit set fail' },
      { label: 'UNIT CODE SUCCESSFULLY CANCELLED', value: 'Unit code successfully cancelled' },
      { label: 'USER CODE ADDED', value: 'User code added' },
      { label: 'USER CODE ALREADY EXISTS', value: 'User code already exists' },
      { label: 'USER CODE DELETED', value: 'User code deleted' },
      { label: 'USER CODE LIMIT EXCEEDED', value: 'User code limit exceeded' },
      { label: 'USER CODE LIMIT SET', value: 'User code limit set' },
      { label: 'USER CODE LIMIT SET FAIL', value: 'User code limit set fail' },
      { label: 'USER CODE NOT ADDED NO SPACE', value: 'User code not added no space' },
      { label: 'USER CODE NOT DELETED DOES NOT EXIST', value: 'User code not deleted does not exist' },
      { label: 'WEEKLY CODE CANCELLATION FAIL ALREADY CANCELLED', value: 'Weekly code cancellation fail already cancelled' },
      { label: 'WEEKLY CODE CANCELLATION FAIL ALREADY EXPIRED', value: 'Weekly code cancellation fail already expired' },
      { label: 'WEEKLY CODE CANCELLATION FAIL DOES NOT EXIST', value: 'Weekly code cancellation fail does not exist' },
      { label: 'WEEKLY CODE LIMIT SET', value: 'Weekly code limit set' },
      { label: 'WEEKLY CODE LIMIT SET FAIL', value: 'Weekly code limit set fail' },
      { label: 'WEEKLY CODE SUCCESSFULLY CANCELLED', value: 'Weekly code successfully cancelled' }
    );

    this.downloadAuditLogForm.controls['lockSerialNumber'].setValue(selectedLockSerialNumbers);
    this.downloadAuditLogForm.controls['lockLocation'].setValue(selectedLockLocations);
    this.downloadAuditLogForm.controls['lockCustomer'].setValue(selectedLockCustomers);
    this.downloadAuditLogForm.controls['lockInterface'].setValue([1, 0]);
    this.downloadAuditLogForm.controls['lockStatus'].setValue([
      AppConstants.LockStatus.stock,
      AppConstants.LockStatus.onsite,
      AppConstants.LockStatus.installed,
      AppConstants.LockStatus.inRepair]
    );
    let selectedLockAuditEvents = [];
    this.lockAuditEvents.slice(0, 12).map((item) => selectedLockAuditEvents.push(item.value));
    this.downloadAuditLogForm.controls['eventTypeDescription'].setValue(selectedLockAuditEvents);
  }

  getDateBasedOnUserTimeZone(dateTime: string) {
    dateTime = this.getValidDateOrEmtpyString(dateTime);
    if (dateTime !== '') {
      const localDateTime = new Date(dateTime + 'Z');
      const localYear = localDateTime.getFullYear();
      const localMonth = localDateTime.getMonth() + 1;
      const localDate = localDateTime.getDate();
      const localDatePart = localYear + '-' + localMonth + '-' + localDate;
      const localHours = localDateTime.getHours();
      const localMinutes = localDateTime.getMinutes().toString().length == 1 ? '0' + localDateTime.getMinutes() : localDateTime.getMinutes();
      const localSeconds = localDateTime.getSeconds();
      const localTimePart = localHours + ':' + localMinutes + ':' + localSeconds;
      dateTime = localDatePart + ' ' + localTimePart;
    }
    return dateTime;
  }

  getValidDateOrEmtpyString(dateTime: string) {
    if (Date.parse(dateTime).toString() === 'NaN' || dateTime === '0001-01-01T00:00:00') {
      dateTime = '';
    }
    return dateTime.replace('T', ' ');
  }

  fetchLocks() {
    this.repo.getLocks();
  }

  viewLockAudit(lockRec: UserLocksModel) {
    const pageTitleSuffix = lockRec
      ? lockRec.location ? `${lockRec.serialNumber} - ${lockRec.location}` : lockRec.serialNumber
      : '';
    this.pageTitle = `Latest audit details for Lock : ${pageTitleSuffix}`;
    this.viewAudit = true;
    lockRec.lockSerialNumber = lockRec.serialNumber;
    this.selViewAuditRec = lockRec;
    lockRec.timeZoneId = this.apptimeZone.currentTimeZoneName;
    this.repo.getAuditDetails(lockRec);
  }

  getPreferredTimeZoneName(): string {
    const preferredTimeZone = this.preferredTimeZoneForm.controls['timeZone'];
    const preferredTimeZoneName = preferredTimeZone !== null && preferredTimeZone !== undefined && preferredTimeZone.value.trim() !== ''
      ? preferredTimeZone.value
      : this.apptimeZone.currentTimeZoneName;

    return preferredTimeZoneName;
  }

  backToLocks() {
    this.pageTitle = AppConstants.PageTitle.audits;
    this.viewAudit = false;
    this.selViewAuditRec = null;
  }

  openDownloadAuditLogsDialog() {
    this.prepareAuditFilterData();
    this.showDownloadAuditLogsDialog = true;
  }

  closeDownloadAuditLogsDialog() {
    this.showDownloadAuditLogsDialog = false;
    this.resetDownloadAuditLogForm();
  }

  resetDownloadAuditLogForm() {
    this.downloadAuditLogForm = this.getDownloadAuditLogForm();
  }

  getDownloadAuditLogForm() {
    return this.formBuilder.group({
      lockSerialNumber: [''],
      lockLocation: [''],
      lockCustomer: [''],
      //timeZoneId: [''],
      lockInterface: [],
      eventTypeDescription: [''],
      lockStatus: [],
      lockUserName: [''],
      reqSummary: [1],
      includeSummary: ['true'],
      inhibitTable: [0],
      includeAuditLogs: ['true'],
      returnCsv: [0],
      returnPdf: [0],
      mailCsv: [0],
      mailPdf: [0],
      earliest: [''],
      latest: [''],
      fileFormat: [this.appLabels.fileFormatCsv]
    });
  }

  downloadAuditLogs() {
    const separator = '|';
    const form = this.downloadAuditLogForm;
    const includeAuditLogs = form.controls['includeAuditLogs'].value;
    if (includeAuditLogs && (includeAuditLogs === 'true' || includeAuditLogs === true))
      form.controls['inhibitTable'].setValue(0);
    else
      form.controls['inhibitTable'].setValue(1);

    const includeSummary = form.controls['includeSummary'].value;
    if (includeSummary && (includeSummary === 'true' || includeSummary === true))
      form.controls['reqSummary'].setValue(1);
    else
      form.controls['reqSummary'].setValue(0);

    const lockLocation: [] = form.controls['lockLocation'].value;
    if (lockLocation && lockLocation.length > 0 && lockLocation.length !== this.lockLocations.length)
      form.controls['lockLocation'].setValue(lockLocation.join(separator));
    else
      form.controls['lockLocation'].setValue(null);

    const lockCustomer: [] = form.controls['lockCustomer'].value;
    if (lockCustomer && lockCustomer.length > 0 && lockCustomer.length !== this.lockCustomers.length)
      form.controls['lockCustomer'].setValue(lockCustomer.join(separator));
    else
      form.controls['lockCustomer'].setValue(null);

    const lockSerialNumber: [] = form.controls['lockSerialNumber'].value;
    if (lockSerialNumber && lockSerialNumber.length > 0 && lockSerialNumber.length !== this.lockSerialNumbers.length)
      form.controls['lockSerialNumber'].setValue(lockSerialNumber.join(separator));
    else
      form.controls['lockSerialNumber'].setValue(null);

    const eventTypeDescription: [] = form.controls['eventTypeDescription'].value;
    if (eventTypeDescription && eventTypeDescription.length > 0 && eventTypeDescription.length !== this.lockAuditEvents.length)
      form.controls['eventTypeDescription'].setValue(eventTypeDescription.join(separator));
    else
      form.controls['eventTypeDescription'].setValue(null);

    const lockStatus: [] = form.controls['lockStatus'].value;
    if (lockStatus && lockStatus.length > 0 && lockStatus.length !== this.lockStatuses.length)
      form.controls['lockStatus'].setValue(lockStatus.join(separator));
    else
      form.controls['lockStatus'].setValue(null);

    const lockInterface: [] = form.controls['lockInterface'].value;
    if (lockInterface && lockInterface.length > 0 && lockInterface.length !== this.lockInterface.length)
      form.controls['lockInterface'].setValue(parseInt(lockInterface.toString()));
    else
      form.controls['lockInterface'].setValue(null);

    const lockUserName = form.controls['lockUserName'].value;
    if (this.appHelper.isNullOrUndefinedOrWhiteSpace(lockUserName))
      form.controls['lockUserName'].setValue(null);

    if (form.controls['fileFormat'].value == this.appLabels.fileFormatPdf)
      form.controls['returnPdf'].setValue(1);
    else
      form.controls['returnPdf'].setValue(0);

    if (form.controls['fileFormat'].value == this.appLabels.fileFormatCsv)
      form.controls['returnCsv'].setValue(1);
    else
      form.controls['returnCsv'].setValue(0);

    const request = form.value;
    this.repo.downloadAudit(request).subscribe((data: BlobPart) => {
      //const blob = new Blob([data], { type: 'application/pdf' });
      if (data === null || data === undefined) {
        this.appNotification.info('No audits found for the given search paramters.')
        return true;
      }

      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'LoxalAuditReport' + new Date().toISOString().replace(/-/g, '').replace(/:/g, '').replace(/T/g, '').slice(0, 14) + '.' + form.controls['fileFormat'].value.toLowerCase();
      link.click();
    }, (err) => {
      this.toastr.error("Download failed.", "Something went wrong");
      console.error('HTTP Error ', err);
    });

    this.closeDownloadAuditLogsDialog();
    this.appNotification.info('Audit report request submitted. File shall be downloaded once generated.');
  }

  emailAuditLogsCsv() {
    this.selViewAuditRec.mailCsv = 1;
    this.selViewAuditRec.mailPdf = 0;

    this.emailAuditLogs();
  }

  emailAuditLogsPdf() {
    this.selViewAuditRec.mailCsv = 0;
    this.selViewAuditRec.mailPdf = 1;

    this.emailAuditLogs();
  }

  emailAuditLogs() {
    const request = this.selViewAuditRec;
    
    request.lockUserName = this.searchAuditForm.controls['lockUserName'].value
      ? this.searchAuditForm.controls['lockUserName'].value
      : null;
    request.eventTypeDescription = this.searchAuditForm.controls['eventTypeDescription'].value
      ? this.searchAuditForm.controls['eventTypeDescription'].value
      : null;
    request.earliest = this.searchAuditForm.controls['earliest'].value
      ? this.searchAuditForm.controls['earliest'].value
      : null;
    request.latest = this.searchAuditForm.controls['latest'].value
      ? this.searchAuditForm.controls['latest'].value
      : null;

    request.timeZoneId = this.getPreferredTimeZoneName();

    this.repo.getAuditDetails(request);
    this.appNotification.success('Email request submitted.');
  }

  onPreferredTimeZoneChange(event) {
    if (event && event.value) {
      const request = this.selViewAuditRec;
      request.mailPdf = 0;
      request.timeZoneId = this.getPreferredTimeZoneName();

      this.repo.getAuditDetails(request);
    }
  }

  searchLockAudits() {
    this.userLocks;
  }
}
