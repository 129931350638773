export class UserLocksModel {
  constructor(
    public serialNumber: string = '',
    public activationCode: string = '',
    public customer: string = '',
    public location: string = '',
    public notes: string = '',
    public lastAuditCollection: string = '',
    public seedCode: string = null,
    public unitType: string = '',
    public codeType: string = '',
    public installedDate: string = '',
    public installationDate: string = '',
    public created: string = '',
    public needsToBeUpdated: number = 0,
    public hasInterface: number = 0,
    public lockSerialNumber: string = '',
    public lockUserName: string = '',
    public company: string = '',
    public lockUserId: number = null,
    public mailPdf: number = 0,
    public mailCsv: number = 0,
    public eventTypeDescription: string = null,
    public earliest: string = null,
    public latest: string = null,
    public searchText: string = null,
    public timeZoneId: string = null,
    public lockType: string = null,
    public status: string = null,
    public cloneOf: string = null
  ) { }
}
