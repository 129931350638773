import { Component, OnInit } from '@angular/core';
import * as AppConstants from '../common/app.constants';
import { AppRoles } from '../common/app.roles';
import { DataService } from '../shared/dataService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Repository } from '../models/repository';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { UserGetRequest } from '../models/UserGetRequest.model';
import { AppNotification } from '../common/app.notification';
import { UserUpdateRequest } from '../models/UserUpdateRequest.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  /** Gets or sets the title for the Units page.*/
  pageTitle = AppConstants.PageTitle.userProfile;

  /** Gets or sets the logged in user name.*/
  loggedInUserName: string;

  /** Gets or sets the logged in user role.*/
  loggedInUserRole: string;

  /** Gets or sets the logged in user is Global Admin or not.*/
  isGlobalAdmin = false;

  /** Gets or sets the logged in user is Owner User or not.*/
  isOwnerUser = false;

  enableValidation = true;

  userForm: FormGroup;

  constructor(private roles: AppRoles
    , private dataService: DataService
    , private appLabels: AppConstants.AppLabels
    , private formBuilder: FormBuilder
    , private repo: Repository
    , private appNotification: AppNotification
    , private spinner: NgxSpinnerService) {
    this.userForm = this.formBuilder.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      company: [''],
      maxArbitraryCodes: [''],
      maxPerpetualCodes: [''],
      maxDailyCodes: [''],
      maxWeeklyCodes: [''],
      globalAdminBlock: [false]
    });
  }

  ngOnInit() {
    this.getLoggedInUserInfo();
  }

  /** Gets the currently logged-in user's details. */
  getLoggedInUserInfo() {
    const token = this.dataService.getAccessToken();
    const decodedToken = this.dataService.getDecodedAccessToken(token);
    if (decodedToken) {
      this.loggedInUserName = decodedToken.unique_name;
      this.loggedInUserRole = decodedToken.Role;
      this.isGlobalAdmin = this.loggedInUserRole.toLowerCase() == this.roles.globalAdmin.toLowerCase();
      this.isOwnerUser = this.loggedInUserRole.toLowerCase() == this.roles.ownerUser.toLowerCase();
      const userRequest: UserGetRequest = { userName: this.loggedInUserName };

      this.repo.getUser(userRequest).subscribe((user: UserGetResponseRecord) => {
        if (user) {
          this.userForm.controls['userName'].setValue(user.userName);
          this.userForm.controls['userName'].disable();
          this.userForm.controls['firstName'].setValue(user.firstName);
          this.userForm.controls['lastName'].setValue(user.lastName);
          this.userForm.controls['phone'].setValue(user.phone);
          this.userForm.controls['email'].setValue(user.email);
          this.userForm.controls['company'].setValue(user.company);
          this.userForm.controls['maxArbitraryCodes'].setValue(user.maxArbitraryCodes);
          this.userForm.controls['maxPerpetualCodes'].setValue(user.maxPerpetualCodes);
          this.userForm.controls['maxDailyCodes'].setValue(user.maxDailyCodes);
          this.userForm.controls['maxWeeklyCodes'].setValue(user.maxWeeklyCodes);
          this.userForm.controls['globalAdminBlock'].setValue(user.globalAdminBlock);
        }
        if (this.isGlobalAdmin) {
          this.enableValidation = false;
          this.userForm.controls['firstName'].disable();
          this.userForm.controls['lastName'].disable();
          this.userForm.controls['phone'].disable();
          this.userForm.controls['email'].disable();
          this.userForm.controls['company'].disable();
        } else {
          this.enableValidation = true;
          this.userForm.controls['firstName'].enable();
          this.userForm.controls['lastName'].enable();
          this.userForm.controls['phone'].enable();
          this.userForm.controls['email'].enable();
          this.userForm.controls['company'].enable();
        }
        this.spinner.hide();
      }, (err) => {
        console.error(err);
        this.spinner.hide();
        this.appNotification.error('Failed to retrieve user information!');
      });
    }
  }

  /** Updates the user information. */
  updateUser() {
    this.spinner.show();
    const updateRequest = new UserUpdateRequest();
    updateRequest.userName = this.loggedInUserName;
    updateRequest.firstName = this.userForm.controls['firstName'].value;
    updateRequest.lastName = this.userForm.controls['lastName'].value;
    updateRequest.phone = this.userForm.controls['phone'].value;
    updateRequest.email = this.userForm.controls['email'].value;
    updateRequest.company = this.userForm.controls['company'].value;
    updateRequest.globalAdminBlock = Number(this.userForm.controls['globalAdminBlock'].value);

    if (this.isOwnerUser) {
      updateRequest.maxArbitraryCodes = this.userForm.controls['maxArbitraryCodes'].value;
      updateRequest.maxPerpetualCodes = this.userForm.controls['maxPerpetualCodes'].value;
      updateRequest.maxDailyCodes = this.userForm.controls['maxDailyCodes'].value;
      updateRequest.maxWeeklyCodes = this.userForm.controls['maxWeeklyCodes'].value;
    }

    this.repo.updateUser(updateRequest).subscribe((response: any) => {
      this.spinner.hide();
      if (response.statusCode == AppConstants.StatusCode.Ok) {
        this.appNotification.success(`Updated details for the user ${this.loggedInUserName}`);
      } else {
        console.error('StatusCode : ', response.StatusCode, '. Message : ', response.Message);
        this.appNotification.error('Failed to update. Something went wrong!');
      }
    }, (err) => {
      console.error(err);
      this.spinner.hide();
      this.appNotification.error('Failed to update. Something went wrong!');
    });
  }

  /** Dicards changes to the user form. */
  discardChanges() {
    this.getLoggedInUserInfo();
  }
}
