export class UserGetResponseRecord {
	constructor(
		public id: number = null,
		public userName: string = '',
		public firstName: string = '',
		public lastName: string = '',
		public phone: string = '',
		public email: string = '',
		public passwordChangePending: number = null,
		public roles: string = '',
    public rolesToDisplay: string = '',
		public group: string = '',
		public company: string = '',
		public maxArbitraryCodes: number = null,
		public maxPerpetualCodes: number = null,
		public maxDailyCodes: number = null,
		public maxWeeklyCodes: number = null,
		public globalAdminBlock: number = 0
	) { }
}
