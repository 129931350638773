import { Injectable } from '@angular/core';

/** Gets constants for special characters/symbols. */
export const SpecialChars = {
  /** , comma symbol */
  comma: ',',
  /** , comma symbol followed by single whitespace*/
  commaWhitespace: ', '
};

/** Gets constants for Toastr notfication properties. */
export const Toastr = {
  /** Position on the UI to display the success notification. */
  successPosition: 'toast-top-center',
  /** Timeout in milliseconds to display the success notification. */
  successTimeout: 3000,

  /** Position on the UI to display the info notification. */
  infoPosition: 'toast-top-center',
  /** Timeout in milliseconds to display the info notification. */
  infoTimeout: 10000,

  /** Position on the UI to display the warning notification. */
  warningPosition: 'toast-top-center',
  /** Timeout in milliseconds to display the warning notification. */
  warningTimeout: 10000,

  /** Position on the UI to display the error notification. */
  errorPosition: 'toast-top-center',
  /** Timeout in milliseconds to display the error notification. */
  errorTimeout: 10000
};

/** Gets constants for general purpose. */
export const Generic = {
  /** Shorter error string. */
  shortErrorString: 'Error occured',
  /** Phone. */
  phone: 'phone',
  /** eMail. */
  email: 'email',
  /** user. */
  user: 'User',
  /** Group. */
  group: 'Group',
  /** 'Code' as a type of message to be sent to the user. */
  messageTypeCode: 'code',
  /** 'Code' as a type of message to be sent to the user. */
  messageTypeArbitraryCode: 'arbitrarycode',
  /** Number of rows per page. */
  rowsPerPage: 10,
  /** Table height. */
  tableHeight: '200px',
  /** Count of locks to activate by GlobalAdmin user. */
  minCountOfLocksToActivateByAdmin: 1,
  /** Assigned locks. */
  AssignedLocks: 'AssignedLocks',
  single: 'Single',
  multiple: 'Multiple'
};

/** Gets constants for various types of Lock code. */
export const LockCodeType = {
  /** Arbitrary. */
  arbitrary: 'Arbitrary',
  /** Perpetual. */
  perpetual: 'Perpetual',
  /** Daily. */
  daily: 'Daily',
  /** Weekly. */
  weekly: 'Weekly'
};

/** Gets constants for various types of Lock code. */
export const LockCodeTypeDisplay = {
  /** Arbitrary. */
  arbitrary: 'Master Code User',
  /** Perpetual. */
  perpetual: 'Unit',
  /** Daily. */
  daily: 'Daily',
  /** Weekly. */
  weekly: 'Weekly'
};

/** Gets constants for various types of Lock Interface. */
export const LockInterface = {
  /** No. */
  no: 0,
  /** Undefined. */
  undefined: 1,
  /** Videofied. */
  videofied: 2,
  /** Ajax. */
  ajax: 3
};

/** Gets constants for various types of Lock Interface. */
export const LockInterfaceDisplay = {
  /** No. */
  no: 'No Interface',
  /** Undefined. */
  undefined: 'Interface',
  /** Videofied. */
  videofied: 'Videofied Alarm Interface',
  /** Ajax. */
  ajax: 'Ajax Ready Alarm Interface'
};

/** Gets constants for various types of Lock. */
export const LockType = {
  /** Ajax Ready External Alarm Keypad. */
  ajaxReadyExternalAlarmKeypad: 'Ajax Ready External Alarm Keypad',
  /** Block Lock. */
  blockLock: 'Block Lock',
  /** Block Lock V2. */
  blockLockV2: 'Block Lock V2',
  /** Face Fix Key Safe. */
  faceFix: 'Face Fix Key Safe',
  /** Face Fix Key Safe V2. */
  faceFixV2: 'Face Fix Key Safe V2',
  /**  S-Trap Key Safe V2.**/
  sTrapV2: 'S-Trap Key Safe V2',
  /** Letterbox Key Safe. */
  letterbox: 'Letterbox Key Safe',
  /** Face Fix. */
  lockingBar: 'Locking Bar',
  /** Lock Converter. */
  lockConverter: 'Lock Converter',
  /** Security Door. */
  securityDoor: 'Security Door',
  /** Security Door V2. */
  securityDoorV2: 'Security Door V2',
  /** Key Cabinet. */
  keyCabinet: 'Key Cabinet',
  /** Key Cabinet V2. */
  keyCabinetV2: 'Key Cabinet V2',
  /** Security Door. */
  externalAlarmKeyPad: 'External/Alarm Key Pad',
  /** Videofied External Alarm Keypad. */
  videofiedExternalAlarmKeypad: 'Videofied External Alarm Keypad',
};

/** Gets display constants for various types of Lock. */
export const LockTypeDisplay = {
  /** Ajax Ready External Alarm Keypad. */
  ajaxReadyExternalAlarmKeypad: 'Ajax Ready External Alarm Keypad',
  /** Block Lock. */
  blockLock: 'Block Lock',
  /** Block Lock V2. */
  blockLockV2: 'Block Lock V2',
  /** Face Fix Key Safe. */
  faceFix: 'Face Fix Key Safe',
  /** Face Fix Key Safe V2. */
  faceFixV2: 'Face Fix Key Safe V2',
  /**  S-Trap Key Safe V2.**/
  sTrapV2: 'S-Trap Key Safe V2',
  /** Letterbox Key Safe. */
  letterbox: 'Letterbox Key Safe',
  /** Face Fix. */
  lockingBar: 'Locking Bar',
  /** Lock Converter. */
  lockConverter: 'Lock Converter',
  /** Security Door. */
  securityDoor: 'Security Door',
  /** Security Door V2. */
  securityDoorV2: 'Security Door V2',
  /** Key Cabinet. */
  keyCabinet: 'Key Cabinet',
  /** Key Cabinet V2. */
  keyCabinetV2: 'Key Cabinet V2',
  /** Security Door. */
  externalAlarmKeyPad: 'External/Alarm Key Pad',
  /** Videofied External Alarm Keypad. */
  videofiedExternalAlarmKeypad: 'Videofied External Alarm Keypad',
};

/** Gets constants for various statusses of Lock. */
export const LockStatus = {
  stock: 'Stock',
  onsite: 'Onsite',
  installed: 'Installed',
  inRepair: 'In Repair'
};

/** Gets display constants for various statusses of Lock. */
export const LockStatusDisplay = {
  stock: 'Stock',
  onsite: 'Onsite',
  installed: 'Installed',
  inRepair: 'In Repair'
};

/** Gets the constants for title of various components/pages. */
export const PageTitle = {
  /** Lock units page title.*/
  units: 'LIST MY LOCKS',
  /** Assigned lock units page title.*/
  assignedUnits: 'LIST ASSIGNED LOCKS',
  /** Users page title.*/
  users: 'MANAGE USERS',
  /** Lock Accees Code page title.*/
  codes: 'MANAGE CODES FOR MY LOCKS',
  /** User Groups page title.*/
  groups: 'MANAGE GROUPS',
  /** Audits page title.*/
  audits: 'LOCK AUDITS',
  /** User Profile page title.*/
  userProfile: 'MY ACCOUNT',
  /** Help documents page title. */
  helpPage: 'HELP DOCUMENTS'
};

/** HTTP Response status codes */
export const StatusCode = {
  /** Ok/Success. */
  Ok: 200
};

/** Provides the text string as properties for labels, table headers, etc., in the application. */
@Injectable({ providedIn: 'root' })
export class AppLabels {
  lockId = 'LOCK ID';
  activationCode = 'ACTIVATION CODE';
  seedCode = 'SEED CODE';
  location = 'LOCATION';
  audit = 'AUDIT';
  event = 'EVENT';
  auditEvent = 'AUDIT EVENT';
  dateTime = 'DATE/TIME';
  dateInstalled = 'INSTALLATION DATE';
  dateAssigned = 'ASSIGNED DATE';
  dateCreated = 'CREATED DATE';
  create = 'CREATE';
  dateDeleted = 'DELETED DATE';
  customer = 'CUSTOMER';
  locksRange = 'LOCKS RANGE';
  notes = 'NOTES';
  user = 'USER';
  users = 'USERS';
  code = 'CODE';
  codeType = 'CODE TYPE';
  lockType = 'LOCK TYPE';
  role = 'ROLE';
  status = 'STATUS';
  email = 'EMAIL';
  confirmEmail = 'CONFIRM EMAIL'
  phone = 'PHONE';
  company = 'COMPANY';
  group = 'GROUP';
  groups = 'GROUPS';
  password = 'PASSWORD';
  name = 'NAME';
  newName = 'NEW NAME';
  userName = 'USERNAME';
  firstName = 'FIRST NAME';
  lastName = 'LAST NAME';
  groupName = 'GROUP NAME';
  groupMembers = 'GROUP MEMBERS';
  arbitraryCode = 'USER KEYPAD CODE';
  serialNumber = 'SERIAL NUMBER';
  lockSerialNumber = 'LOCK SERIAL NUMBER';
  cloneFrom = 'CLONE FROM';
  cloneTo = 'CLONE TO';
  clonedBy = 'CLONED BY';
  cloneOf = 'CLONE OF';
  currentSeedCode = 'CURRENT SEED CODE';
  newSeedCode = 'NEW SEED CODE';
  newSeedCodePlaceHolder = '16 CHARACTER HEX STRING';
  count = 'COUNT';
  countOfLocks = 'LOCKS COUNT';
  owner = 'OWNER';
  ownerUser = 'OWNER USER';
  ownerUserName = 'OWNER USERNAME';
  singleUser = 'Single user';
  multipleUsers = 'Multiple users';
  startingRange = 'STARTING RANGE';
  numberOfUsers = 'NUMBER OF USERS';
  includeAuditLogs = 'INCLUDE LOGS';
  includeAuditSummary = 'INCLUDE SUMMARY';
  fileFormat = "FILE FORMAT";
  fileFormatCsv = "CSV";
  fileFormatPdf = "PDF";
  earliestDate = 'EARLIEST DATE'
  latestDate = 'LATEST DATE'

  selectedLocks = 'VIEW SELECTED LOCKS';
  selectLock = 'SELECT LOCK';
  selectRole = 'SELECT ROLE';
  selectUser = 'SELECT USER';
  selectOwner = 'SELECT OWNER';
  selectLockType = 'SELECT LOCK TYPE';
  selectGroup = 'SELECT GROUP';
  selectCodeType = 'SELECT CODE TYPE';
  selectStart = 'SELECT START';
  selectEnd = 'SELECT END';
  selectInterface = 'SELECT INTERFACE'
  select = 'SELECT';

  yes = 'YES';
  no = 'NO';
  to = 'TO';

  downloadAuditLog = 'DOWNLOAD AUDIT LOGS';
  resendUserCode = 'RESEND MASTER LOCK ACCESS CODE';
  lockSyncRequired = 'SYNC REQUIRED';
  interface = 'INTERFACE';
  noInterface = "NO"
  undefinedInterface = "INTERFACE"
  ajaxReadyInterface = 'AJAX READY';
  videofiedInterface = 'VIDEOFIED';
  genArbitraryCode = 'GENERATE MASTER LOCK ACCESS CODE';
  changeSeedCode = 'GENERATE SEED CODE';
  changePassword = 'CHANGE PASSWORD';
  cloneLock = 'CLONE LOCK';
  activateLock = 'ACTIVATE LOCK';
  deActivateLock = 'DEACTIVATE LOCK';
  activateMultiLocks = 'ACTIVATE MULTIPLE LOCKS';
  manageCodes = 'MANAGE CODES';
  manageLock = 'MANAGE LOCK';
  lockDetails = 'LOCK DETAILS';
  auditDetails = 'AUDIT DETAILS';
  userDetails = 'USER DETAILS';
  userLocks = 'LOCKS';
  editUserDetails = 'EDIT USER DETAILS';
  editLockDetails = 'EDIT LOCK DETAILS';
  newActivationCode = 'NEW ACTIVATION CODE';
  generateAccessCode = 'ASSIGN A CODE';
  removeAccessCode = 'REMOVE A CODE';
  addToGroup = 'ADD TO GROUP';
  removeFromGroup = 'REMOVE FROM GROUP';
  createLockUserGroup = 'CREATE LOCK USER GROUP';
  deleteLockUserGroup = 'DELETE LOCK USER GROUP';
  addArbitaryLockUserToGroup = 'ADD ARIBTRARY LOCK USER TO GROUP';
  deleteArbitaryLockUserFromGroup = 'DELETE ARIBTRARY LOCK USER FROM GROUP';
  addUserByOwnerUser = 'ADD MANAGER/AUDIT COLLECTOR';
  deleteUserByOwnerUser = 'DELETE MANAGER/AUDIT COLLECTOR';
  addUserByUser = 'ADD AUDIT COLLECTOR';
  deleteUserByUser = 'DELETE AUDIT COLLECTOR';
  addUserByGlobalAdmin = 'ADD OWNER USER/AUDIT COLLECTOR';
  deleteUserByGlobalAdmin = 'DELETE OWNER USER';
  addLockUser = 'ADD LOCK USER';
  deleteLockUser = 'DELETE LOCK USER';
  addUser = 'ADD USER';
  deleteUser = 'DELETE USER';
  convertLockUser = 'CONVERT LOCK USER';
  blockGlobalAdminAccess = 'BLOCK PROFILE ACCESS BY ADMIN';

  clickToView = 'CLICK TO VIEW';
  clickToAssign = 'CLICK TO ASSIGN';
  clickToAdd = 'CLICK TO ADD';
  clickToEdit = 'CLICK TO EDIT';
  clickToRemove = 'CLICK TO REMOVE';
  clickToManage = 'CLICK TO MANAGE';

  assign = 'ASSIGN';
  cancel = 'CANCEL';

  backToGroups = 'BACK TO GROUPS';
  backToAudits = 'BACK TO LOCK AUDITS';
  backToLocks = 'BACK TO LOCKS';

  globalSearchPlaceholder = 'SEARCH - LOCK ID / LOCATION / USER';
  sendCodeTo = 'SEND CODE TO';

  maxArbitraryCodes = 'USER CODES';
  maxPerpetualCodes = 'UNIT CODES';
  maxDailyCodes = 'DAILY CODES';
  maxWeeklyCodes = 'WEEKLY CODES';

  contactInfoTitle = 'CONTACT INFORMATION';
  profileInfoTitle = 'PROFILE INFORMATION';
  lockCodeCountTitle = 'LOCK ACCESS CODE LIMITS';
  lockCodeCountSubTitle = 'SET THE MAXIMUM NUMBER OF CODES PER LOCK.';
  convertLockUserDescription = 'CONVERT TEMPORARY USER TO REGULAR USER AND SEND A MASTER LOCK ACCESS CODE.';

  manageLocksCardSubTitle = 'Activate, list & clone my Locks';
  manageLocksCardSubTitleForAdmin = 'List & activate multiple Locks';
  manageUsersCardSubTitle = 'Add users and manage individual users';
  codesCardSubTitle = 'Search for locks & issue lock codes to users and groups';
  manageGroupsCardSubTitle = 'Create user groups and manage individual users';
  auditsCardSubTitle = 'Identify your locks and view audits';
  viewAssignedLocksSubTitle = 'View assigned locks';

  contactInfoEmail = "g.white@loxalsecurity.com";

  arbitraryUserInidicator = "(P)";
}
