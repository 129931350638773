import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Repository } from '../models/repository';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { DataService } from '../shared/dataService';
import { finalize } from 'rxjs/operators';
import * as AppConstants from '../common/app.constants';
import { AppNotification } from '../common/app.notification';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})
export class Login implements OnInit {

  constructor(private data: DataService, private router: Router,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private repo: Repository, private formBuilder: FormBuilder,
    private route: ActivatedRoute, private appLabels: AppConstants.AppLabels, private appNotification: AppNotification) {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.resetPasswordForm = this.formBuilder.group({
      passwordResetCode: ['', Validators.required],
      userName: [''],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    this.ownerUserCreationForm = this.formBuilder.group({
      proposedOwnerUserName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', Validators.required],
      confirmedEmailAddress: ['', Validators.required],
      phone: ['', Validators.required],
      lockSerialNumber: ['', Validators.required],
      activationCode: ['', Validators.required]
    })
  }

  forgotPasswordTitle = '';
  resetPasswordTitle = '';
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  ownerUserCreationForm: FormGroup;

  showForgotPasswordDialog = false;
  showResetPasswordDialog = false;
  showOwnerUserCreationDialog = false;

  LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';
  passwordChangePending = false;

  authToken = '';
  queryParam = '';

  errorMessage = '';
  public creds = {
    username: '',
    password: ''
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.resetPasswordForm.controls['passwordResetCode'].reset();
      this.queryParam = params['resetCode'];
      if (this.queryParam) {
        this.resetPasswordForm.controls['passwordResetCode'].setValue(this.queryParam);
        // this.resetPasswordForm.controls['passwordResetCode'].disable();
        this.resetPasswordTitle = 'RESET PASSWORD';
        this.showResetPasswordDialog = true;
      }
    });
  }

  onLogin() {
    this.errorMessage = '';
    this.spinner.show();
    this.data.login(this.creds)
      .subscribe(success => {
        if (success) {
          const userReq = new UserGetResponseRecord();
          userReq.userName = this.creds.username;

          this.data.fetchLoggedInUserDetails(userReq).subscribe((loginUser) => {
            // let loggedInUser;
            // if(loginUser && Array.isArray(loginUser) && loginUser.length > 1) {
            //  loggedInUser = loginUser.filter((user) => {
            //    return (user.userName == userReq.userName)
            //  });
            // }
            // if (loginUser && loginUser.userName) {
            //  loggedInUser = (loginUser.userName == userReq.userName);
            // }

            // if(loggedInUser && loggedInUser.length > 0 && loggedInUser[0].passwordChangePending == 1){
            if (loginUser && loginUser.userName && loginUser.passwordChangePending == 1) {
              this.passwordChangePending = true;
              this.showResetPassword(userReq.userName, 'CHANGE DEFAULT PASSWORD');
            } else {
              this.afterSuccessfulLogin();
            }
          }, (err) => {
            this.toastr.error(err, 'Error', {
              positionClass: 'toast-top-center',
              timeOut: 3000
            });
          });
        } else {
          this.toastr.error('Login Failed!', 'Error', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        this.toastr.error('Login Failed!', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
  }

  afterSuccessfulLogin() {
    // this.toastr.success("successfully logged in!",'',{
    //  positionClass : 'toast-top-center',
    //  timeOut: 2000
    // });
    this.router.navigate(['home']);
    this.repo.loadUsersGroups();
    // this.spinner.hide();
  }

  showForgotPassword(title?: string) {
    this.forgotPasswordTitle = title ? title : 'Find Account';
    this.forgotPasswordForm.reset();
    this.showForgotPasswordDialog = true;

    // if(this.passwordChangePending){
    //   this.authToken = this.data.getAccessToken();
    //   localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
    // }
  }

  hideForgotPassword() {
    this.forgotPasswordForm.reset();
    this.showForgotPasswordDialog = false;
  }

  showResetPassword(username?: string, title?: string) {
    this.resetPasswordTitle = title ? title : 'RESET PASSWORD';
    this.resetPasswordForm.reset();
    this.resetPasswordForm.controls['passwordResetCode'].enable();
    if (this.passwordChangePending) {
      this.resetPasswordForm.controls['userName'].setValue(username);
    }
    this.showResetPasswordDialog = true;

    if (this.passwordChangePending) {
      this.authToken = this.data.getAccessToken();
      localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
    }
  }

  hideResetPassword() {
    this.resetPasswordForm.reset();
    this.showResetPasswordDialog = false;
    localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
  }

  findAccount() {
    if (this.forgotPasswordForm.valid) {
      this.data.generatePasswordResetLink(this.forgotPasswordForm.value)
        .subscribe((resetInitiateRes: any) => {
          this.spinner.hide();
          if (!resetInitiateRes.message) {
            this.toastr.error(`Failed to find the Account`, '', {
              positionClass: 'toast-top-center',
              timeOut: 3000
            });
          } else if (resetInitiateRes.message && (resetInitiateRes.message.toLowerCase().indexOf('fail') > -1
            || resetInitiateRes.message.toLowerCase().indexOf('invalid') > -1)) {
            this.toastr.error(resetInitiateRes.message, '', {
              positionClass: 'toast-top-center',
              timeOut: 3000
            });
          } else {
            this.toastr.success('An email to reset the password has been sent to your email address. Please follow the instructions in it.', resetInitiateRes.message, {
              positionClass: 'toast-top-center',
              timeOut: 10000
            });
            this.hideForgotPassword();
            // this.showResetPassword();
          }
        }, (err) => {
          this.spinner.hide();
          this.toastr.error('Failed to find the Account', '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
          // this.hideForgotPassword();
        });
    } else {
      this.forgotPasswordForm.controls['username'].markAsTouched();
    }
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      const pass = this.resetPasswordForm.controls['password'].value;
      const confPass = this.resetPasswordForm.controls['confirmPassword'].value;
      if (pass != confPass) {
        this.toastr.error(`New password and confirm new password does not match`, 'Passwords mismatch', {
          positionClass: 'toast-top-center',
          timeOut: 4000
        });
      } else {
        this.data.resetPassword(this.resetPasswordForm.value)
          .subscribe((resetPasswordRes: any) => {
            this.spinner.hide();
            if (!resetPasswordRes.message) {
              this.toastr.error(`Failed to reset the password`, '', {
                positionClass: 'toast-top-center',
                timeOut: 10000
              });
            } else if (resetPasswordRes.message && resetPasswordRes.message.toLowerCase().indexOf('fail') > -1) {
              this.toastr.error(resetPasswordRes.message, '', {
                positionClass: 'toast-top-center',
                timeOut: 10000
              });
            } else {
              this.toastr.success(resetPasswordRes.message, '', {
                positionClass: 'toast-top-center',
                timeOut: 5000
              });
              if (this.passwordChangePending) {
                this.passwordChangePending = false;
                // this.data.setAccessToken(this.authToken);
                // this.afterSuccessfulLogin();
                // this.hideResetPassword();
              }
              this.hideResetPassword();
            }
          }, (err) => {
            this.spinner.hide();
            this.toastr.error('Failed to reset the password', '', {
              positionClass: 'toast-top-center',
              timeOut: 3000
            });
            // this.hideResetPassword();
          });
      }
    } else {
      this.forgotPasswordForm.controls['passwordResetCode'].markAsTouched();
      this.forgotPasswordForm.controls['password'].markAsTouched();
      this.forgotPasswordForm.controls['confirmPassword'].markAsTouched();
    }
  }

  initialPasswordChange() {
    if (this.passwordChangePending) {
      this.resetPasswordForm.controls['passwordResetCode'].disable();
    }
    if (this.resetPasswordForm.valid) {
      const pass = this.resetPasswordForm.controls['password'].value;
      const confPass = this.resetPasswordForm.controls['confirmPassword'].value;
      if (pass != confPass) {
        this.toastr.error(`New password and confirm new password does not match`, 'Passwords mismatch', {
          positionClass: 'toast-top-center',
          timeOut: 10000
        });
      } else {
        window.localStorage.setItem('authenticationToken', this.authToken);
        this.data.updatePassword(this.resetPasswordForm.value)
          .pipe(finalize(() => {
            localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);
          }))
          .subscribe((resetPasswordRes: any) => {
            this.spinner.hide();
            if (!resetPasswordRes.message) {
              this.toastr.error(`Failed to reset the password`, '', {
                positionClass: 'toast-top-center',
                timeOut: 10000
              });
            } else if (resetPasswordRes.message && resetPasswordRes.message.toLowerCase().indexOf('fail') > -1) {
              this.toastr.error(resetPasswordRes.message, '', {
                positionClass: 'toast-top-center',
                timeOut: 10000
              });
            } else {
              this.toastr.success('Password has been reset. Login now with new credentials.', 'Success', {
                positionClass: 'toast-top-center',
                timeOut: 5000
              });
              if (this.passwordChangePending) {
                this.passwordChangePending = false;
                // this.data.setAccessToken(this.authToken);
                // this.afterSuccessfulLogin();
                // this.hideResetPassword();
              }
              this.hideResetPassword();
            }
          }, (err) => {
            this.spinner.hide();
            this.toastr.error('Failed to reset the password', '', {
              positionClass: 'toast-top-center',
              timeOut: 10000
            });
            // this.hideResetPassword();
          }, () => {
          });
      }
    } else {
      if (!this.passwordChangePending) {
        this.forgotPasswordForm.controls['passwordResetCode'].markAsTouched();
      }
      this.forgotPasswordForm.controls['password'].markAsTouched();
      this.forgotPasswordForm.controls['confirmPassword'].markAsTouched();
    }
  }

  showOwnerUserCreation() {
    this.ownerUserCreationForm.reset();
    this.showOwnerUserCreationDialog = true;
  }

  hideOwnerUserCreation() {
    this.showOwnerUserCreationDialog = false;
    this.ownerUserCreationForm.reset();
  }

  createOwnerUser() {
    if (this.ownerUserCreationForm.valid) {
      this.repo.createOwnerUserAndActivateLock(this.ownerUserCreationForm.value).subscribe(response => {
        this.spinner.hide();
        if (response && response.statusCode === 200) {
          this.appNotification.success('Submited request to create owner user. Please check email for instructions to access portal.', 'Success');
          this.hideOwnerUserCreation();
        }
        else {
          const msg = response && response.message ? response.message : 'Something went wrong. Try again with valid inputs.'
          console.error(msg);
          this.appNotification.error(msg, 'Request failed');
        }
      }, (err) => {
        this.spinner.hide();
        console.error(err);
        this.appNotification.error(`Unable to create owner user. Try again after sometime. Details: ${err}`, 'Something went wrong');
      });
    }
    else {
      this.appNotification.error('Provide all inputs.', 'Input required');
    }
  }
}
