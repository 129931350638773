import { Component, HostListener } from '@angular/core';
import { DataService } from '../shared/dataService';
import { Router } from '@angular/router';
import { UserLocksModel } from '../models/UserLocksModel.model';
import { Repository } from '../models/repository';
import * as $ from 'jquery';
import { LockUsersDetails } from '../models/LockUserAccess.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuditResponseRecord } from '../models/AuditResponseRecord.model';
import { LockUpdateRequest } from '../models/LockUpdateRequest.model';
import * as AppConstants from '../common/app.constants';
import { AppRoles } from '../common/app.roles';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { Message } from 'primeng/components/common/api';
import { LockGetRequest } from '../models/LockGetRequest.model';
import { GenerateCode } from '../models/GenerateCode.model';
import { AppNotification } from '../common/app.notification';
import { LockUsers } from '../models/LockUsers.model';
import { concat, forkJoin, Observable } from 'rxjs';
import { SendMessage } from '../models/SendMessage.model';
import { LockUserGroup } from '../models/LockUserGroup.model';
import { LockGlobalAdmin } from '../models/LockGlobalAdmin.model';
import * as AppHelper from '../common/app.helper';
import { concatMap, tap } from 'rxjs/operators';
import { observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { ActiveLock, ActiveLockUser } from '../models/ActiveLockUsersAndLocks.model';

@Component({
  selector: 'units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent {
  tblDheight = AppConstants.Generic.tableHeight;
  userRole: string;
  today = new Date();
  viewUserLocks = false;
  showActivateLockDialog = false;
  showDeActivateLockDialog = false;
  showActivateMultiLocksDialog = false;
  showCloneLockDialog = false;
  showChangeSeedCodeDialog = false;
  showEditLockDetailsDialog = false;
  showResendUserCodeDialog = false;
  disableGenerate = true;
  msgs: Message[] = [];
  disableCloning = false;
  showGenerateCodeInputDialog = false;
  showRemoveCodeInputDialog = false;
  showConfirmationSection = false;
  isConvertingToArbitraryUser = false;
  isSearch = false;
  displayAssignedLocks = false;
  serNumReadOnly = true;
  viewAudit = false;
  id = '';
  required = '';
  submitted = '';
  cloneFromSelected = '';

  cols: any[];
  cols1: any[];
  cols2: any[];
  filteredUserLocks: any[];

  /** Gets or sets the title for the Units page.*/
  pageTitle = AppConstants.PageTitle.units;

  /** Gets or sets the number of rows per page to be displayed. */
  rowsPerPage = AppConstants.Generic.rowsPerPage;

  /** Boolean flag representing the current logged in user is GlobaAdmin or not. */
  isGlobalAdmin = false;

  /** Boolean flag representing the current logged in user is OwnerUser or not. */
  isOwnerUser = false;

  /** Boolean flag representing the current logged in user is Manager or not. */
  isManager = false;

  /** Array of seleceted locks. */
  selectedLocks: UserLocksModel[];

  /** Gets or sets the options menu width.*/
  optionsMenuWidth = '350px';

  constSecretHidden = this.appHelper.constSecretHidden;

  locksArray: Array<UserLocksModel> = this.repo.locks;
  locksDropDown = [];
  locksFromDropdown = [];
  locksToDropdown = [];
  assignedLocksDropdown = [];
  assignedLocksArray: LockGlobalAdmin[] = [];
  ownerUsersDropDown = [];
  selViewAuditRec: LockUsersDetails;
  activateLockForm: FormGroup;
  activateMultiLocksForm: FormGroup;
  deActivateLockForm: FormGroup;
  cloneLockForm: FormGroup;
  changeSeedCodeForm: FormGroup;
  searchForm: FormGroup;
  generateCodeInputForm: FormGroup;
  removeCodeInputForm: FormGroup;
  editLockDetailsForm: FormGroup;
  resendUserCodeForm: FormGroup;
  lockUsersDetailsWithCode: LockUsersDetails[] = [];
  lockUsersArray: Array<LockUsers> = this.repo.lockUsers;
  groupsArray: Array<LockUserGroup> = this.repo.lockUserGroups;
  lockUsersDropDown: Array<any> = [];
  activeLockUsersDropDown: Array<any> = [];
  activeLocksDropDown: Array<any> = [];
  groupLockUsersDropDown: Array<any> = [];
  groupsDropDown: Array<any> = [];

  lockTypeDropDown = [
    { label: AppConstants.LockTypeDisplay.ajaxReadyExternalAlarmKeypad, value: AppConstants.LockType.ajaxReadyExternalAlarmKeypad },
    { label: AppConstants.LockTypeDisplay.blockLock, value: AppConstants.LockType.blockLock },
    { label: AppConstants.LockTypeDisplay.blockLockV2, value: AppConstants.LockType.blockLockV2 },
    { label: AppConstants.LockTypeDisplay.externalAlarmKeyPad, value: AppConstants.LockType.externalAlarmKeyPad },
    { label: AppConstants.LockTypeDisplay.faceFix, value: AppConstants.LockType.faceFix },
    { label: AppConstants.LockTypeDisplay.faceFixV2, value: AppConstants.LockType.faceFixV2 },
    { label: AppConstants.LockTypeDisplay.keyCabinet, value: AppConstants.LockType.keyCabinet },
    { label: AppConstants.LockTypeDisplay.keyCabinetV2, value: AppConstants.LockType.keyCabinetV2 },
    { label: AppConstants.LockTypeDisplay.letterbox, value: AppConstants.LockType.letterbox },
    { label: AppConstants.LockTypeDisplay.lockConverter, value: AppConstants.LockType.lockConverter },
    { label: AppConstants.LockTypeDisplay.lockingBar, value: AppConstants.LockType.lockingBar },
    { label: AppConstants.LockTypeDisplay.securityDoor, value: AppConstants.LockType.securityDoor },
    { label: AppConstants.LockTypeDisplay.securityDoorV2, value: AppConstants.LockType.securityDoorV2 },
    { label: AppConstants.LockTypeDisplay.sTrapV2, value: AppConstants.LockType.sTrapV2 },
    { label: AppConstants.LockTypeDisplay.videofiedExternalAlarmKeypad, value: AppConstants.LockType.videofiedExternalAlarmKeypad }
  ];

  lockStatusDropDown = [
    { label: AppConstants.LockStatusDisplay.stock, value: AppConstants.LockStatus.stock },
    { label: AppConstants.LockStatusDisplay.onsite, value: AppConstants.LockStatus.onsite },
    { label: AppConstants.LockStatusDisplay.installed, value: AppConstants.LockStatus.installed },
    { label: AppConstants.LockStatusDisplay.inRepair, value: AppConstants.LockStatus.inRepair }
  ];

  codeTypeDropdown = [
    { label: AppConstants.LockCodeTypeDisplay.arbitrary, value: AppConstants.LockCodeType.arbitrary.toLowerCase() },
    { label: AppConstants.LockCodeTypeDisplay.perpetual, value: AppConstants.LockCodeType.perpetual.toLowerCase() },
    { label: AppConstants.LockCodeTypeDisplay.daily, value: AppConstants.LockCodeType.daily.toLowerCase() },
    { label: AppConstants.LockCodeTypeDisplay.weekly, value: AppConstants.LockCodeType.weekly.toLowerCase() }
  ];

  lockInterfaceDropDown = [
    { label: AppConstants.LockInterfaceDisplay.no, value: AppConstants.LockInterface.no },
    { label: AppConstants.LockInterfaceDisplay.undefined, value: AppConstants.LockInterface.undefined },
    { label: AppConstants.LockInterfaceDisplay.videofied, value: AppConstants.LockInterface.videofied },
    { label: AppConstants.LockInterfaceDisplay.ajax, value: AppConstants.LockInterface.ajax }
  ];

  /** Gets or sets the key-value pair of userId and accessCode for the lock. */
  lockUserIdAndAccessCode: { [key: string]: string } = {};

  genCodeForUserOrGroup: string;

  /** Gets selections/targets to which the generated access code to be sent for the user. */
  messageMediumSelection: string[] = [];
  constPhone = AppConstants.Generic.phone;
  constEmail = AppConstants.Generic.email;
  constUser = AppConstants.Generic.user;
  constGroup = AppConstants.Generic.group;
  constRange = 'range';
  constCount = 'count';

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.tblDheight = $(window).height() - 260 + 'px';
  }

  constructor(private data: DataService, private router: Router, private repo: Repository,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private appLabels: AppConstants.AppLabels, private appRoles: AppRoles, private appNotification: AppNotification,
    private apptimeZone: AppHelper.AppTimeZone, private appHelper: AppHelper.Helper) {
    this.viewUserLocks = false;
    this.viewAudit = false;
    this.selViewAuditRec = null;
    this.tblDheight = $(window).height() - 260 + 'px';
    this.userRole = data.getLoginUserRole();
    this.isGlobalAdmin = this.userRole.toLowerCase() == appRoles.globalAdmin.toLowerCase();
    this.isOwnerUser = this.userRole.toLowerCase() == appRoles.ownerUser.toLowerCase();
    this.isManager = this.userRole.toLowerCase() == appRoles.user.toLowerCase();
    this.genCodeForUserOrGroup = this.constUser.toLowerCase();

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state;
    if (state && this.isGlobalAdmin) {
      this.displayAssignedLocks = state.locks.queryParams.displayAssignedLocks;
      if (this.displayAssignedLocks) {
        this.repo.getAssignedLocks();
        this.pageTitle = AppConstants.PageTitle.assignedUnits;
      }
    } else {
      this.viewLocks();
      this.pageTitle = AppConstants.PageTitle.units;
    }

    this.cols = [
      //{ field: 'serialNumber', header: appLabels.lockId + ' (TYPE)' },
      { field: 'status', header: appLabels.status },
      { field: 'hasInterface', header: appLabels.interface },
      { field: 'location', header: appLabels.location },
      { field: 'notes', header: appLabels.notes },
      { field: 'ownerUser', header: appLabels.owner + ' (' + appLabels.userName + ')' },
      //{ field: 'ownerUserName', header: appLabels.ownerUserName },
      { field: 'customer', header: appLabels.customer },
      { field: 'lastAuditCollection', header: appLabels.audit },
      { field: 'created', header: appLabels.dateCreated }
    ];

    this.cols1 = [
      { field: 'lockUserName', header: appLabels.user },
      { field: 'keypadCode', header: appLabels.code },
      { field: 'codeTypeDescription', header: appLabels.codeType },
      { field: 'added', header: appLabels.dateAssigned }
    ];

    this.cols2 = [
      { field: 'dateTime', header: 'Date/Time' },
      { field: 'lockUserName', header: 'User' },
      { field: 'eventType', header: 'Event' }
    ];

    this.searchForm = this.formBuilder.group({
      searchText: ['']
    });

    this.activateLockForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
      activationCode: ['', Validators.required]
    });

    this.activateMultiLocksForm = this.formBuilder.group({
      countOfLocksToActivate: [AppConstants.Generic.minCountOfLocksToActivateByAdmin, Validators.required],
      serialNumbers: ['', Validators.required],
      OwnerUserId: ['', Validators.required],
      lockType: [''],
      fromLock: [''],
      toLock: [''],
      isCountOrRange: [''],
      Interface: [0]
    });

    this.deActivateLockForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
      ownerUserId: ['', Validators.required],
      ownerUserName: [''],
      ownerUserFullName: ['-']
    });

    this.cloneLockForm = this.formBuilder.group({
      newSeedCode: ['', Validators.required],
      oldSeedCode: [''],
      serialNumber: ['', Validators.required]
    });

    this.changeSeedCodeForm = this.formBuilder.group({
      oldSeedCode: [''],
      newSeedCode: ['', Validators.required],
      serialNumber: ['', Validators.required]
    });

    this.resendUserCodeForm = this.formBuilder.group({
      lockSerialNumber: [''],
      lockUserId: [''],
      lockUser: ['', Validators.required],
      messageMedium: ['', Validators.required]
    });

    this.generateCodeInputForm = this.formBuilder.group({
      lockSerialNumber: ['', Validators.required],
      codeType: ['', Validators.required],
      lockUserId: [''],
      lockUser: [''],
      lockUserGroupName: [''],
      messageMedium: [''],
      isCodeForUserOrGroup: ['']
    });

    this.removeCodeInputForm = this.formBuilder.group({
      lockSerialNumber: ['', Validators.required],
      lockUser: ['', Validators.required],
      codeType: ['', Validators.required]
    });

    this.editLockDetailsForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
      location: [''],
      customer: [''],
      notes: [''],
      installationDate: [''],
      Interface: [0],
      LockType: [''],
      status: ['']
    });

    this.getAvailableLocks();

    this.resetGenerateCodeInputForm();
    this.activateMultiLocksForm.controls['isCountOrRange'].setValue(this.constRange);
    this.removeCodeInputForm.reset();
    this.buildOwnerUsersDropDown();
    this.enableOrDisableUserAndGroupControls();
    this.buildUsersDropDown();
    this.buildGroupsDropDown();
  }

  resetGenerateCodeInputForm() {
    this.generateCodeInputForm.reset();
    this.generateCodeInputForm.controls['isCodeForUserOrGroup'].setValue(this.constUser.toLowerCase());
    this.onUserOrGroupRadioClick(this.constUser);
  }

  getUpdatedLockUsersAndGroups() {
    this.repo.getLockUsers(new UserLocksModel());

    this.repo.getUpdatedLockUserGroups().subscribe((lockUserGroups: LockUserGroup[]) => {
      if (lockUserGroups && lockUserGroups.length > 0) {
        this.groupsArray = lockUserGroups;
        this.buildGroupsDropDown();
      }
    });
  }

  buildUsersDropDown() {
    if (this.lockUsersArray) {
      this.lockUsersDropDown = [];
      this.groupLockUsersDropDown = [];
      this.lockUsersArray.forEach((lockUser: LockUsers) => {
        let username = lockUser.name;
        if (lockUser.arbitraryKeypadCode && lockUser.arbitraryKeypadCode > 0) {
          username = username + " " + this.appLabels.arbitraryUserInidicator;
          this.groupLockUsersDropDown.push(
            { label: username, value: lockUser.id }
          );
        }
        this.lockUsersDropDown.push(
          { label: username, value: lockUser }
        );
      });
    }
  }

  buildGroupsDropDown() {
    if (this.groupsArray) {
      this.groupsDropDown = [];
      this.groupsArray.forEach((lockUserGroup: LockUserGroup) => {
        this.groupsDropDown.push(
          { label: lockUserGroup.groupName, value: lockUserGroup.groupName }
        );
      });
    }
  }

  get currentTimeZoneOffset(): string {
    return this.apptimeZone.currentTimeZoneOffset;
  }

  get currentTimeZoneName(): string {
    return this.apptimeZone.currentTimeZoneName;
  }

  get auditDetails(): AuditResponseRecord[] {
    return this.repo.auditDetails;
  }

  get userLocks(): any[] {

    if (this.isSearch && this.searchForm && this.searchForm.controls['searchText'].value) {
      //return this.searchedLocks;
      this.formSubmit();
      return this.filteredUserLocks === null || this.filteredUserLocks === undefined
        ? []
        : this.filteredUserLocks;
    }

    if (this.displayAssignedLocks && this.isGlobalAdmin) {
      if (this.isSearch && this.searchForm && this.searchForm.controls['searchText'].value) {
        this.isSearch = false;
        const request = new LockGetRequest();
        request.serialNumber = this.searchForm.controls['searchText'].value;
        this.repo.getAssignedLocks(request);
      }
      this.buildAssignedLocksDropdown(this.repo.assignedLocks);

      return this.repo.assignedLocks;
    }

    return this.repo.locks;
  }

  get lockUsers(): LockUsersDetails[] {
    const lockUsers = this.repo.lockUsersAccess;
    lockUsers.forEach((user) => {
      user.codeTypeDescription.toLowerCase() == AppConstants.LockCodeType.arbitrary.toLowerCase()
        ? user.codeTypeDescription = AppConstants.LockCodeTypeDisplay.arbitrary
        : user.codeTypeDescription.toLowerCase() == AppConstants.LockCodeType.perpetual.toLowerCase()
          ? user.codeTypeDescription = AppConstants.LockCodeTypeDisplay.perpetual
          : user.codeTypeDescription = user.codeTypeDescription;
    });
    return lockUsers;
  }

  buildAssignedLocksDropdown(assignedLocks: LockGlobalAdmin[]) {
    if (assignedLocks && assignedLocks.length > 0) {
      this.assignedLocksArray = assignedLocks;
      this.assignedLocksDropdown = [];
      assignedLocks.forEach((assignedLock) => {
        this.assignedLocksDropdown.push(
          { label: assignedLock.serialNumber, value: assignedLock.serialNumber }
        );
      });
    }
  }

  onAssignedLocksDropdownChange() {
    const form = this.deActivateLockForm;
    if (form && form.controls['serialNumber']) {
      const selectedAssignedLockId = form.controls['serialNumber'].value;
      const selectedAssignedLock = this.assignedLocksArray
        .find(assingedLock => assingedLock.serialNumber.toLowerCase() == selectedAssignedLockId.toLowerCase());
      if (selectedAssignedLock) {
        form.controls['ownerUserId'].setValue(selectedAssignedLock.ownerUserId);
        form.controls['ownerUserFullName'].setValue(selectedAssignedLock.ownerUser);
      }
    }
  }

  viewLocks() {
    this.repo.getLocks();
  }

  viewSelLockUsers(lockDetails: UserLocksModel) {
    this.viewUserLocks = true;
    this.pageTitle = `USERS OF LOCK : ${lockDetails.serialNumber}`;
    lockDetails.lockSerialNumber = lockDetails.serialNumber;
    this.repo.getLockUserAccess(lockDetails);
  }

  viewLockAudit(lockRec: LockUsersDetails) {
    this.viewUserLocks = false;
    this.viewAudit = true;
    this.selViewAuditRec = lockRec;
    this.repo.getAuditDetails(lockRec);
  }

  backToLocks() {
    this.viewUserLocks = false;
    this.pageTitle = AppConstants.PageTitle.units;
  }

  backToUserLocks() {
    this.viewUserLocks = true;
    this.viewAudit = false;
    this.selViewAuditRec = null;
    this.pageTitle = AppConstants.PageTitle.units;
  }

  /** Gets the list of users. */
  get users(): UserGetResponseRecord[] {
    return this.repo.users;
  }

  /** Gets the list of Owner users. */
  get ownerUsers(): UserGetResponseRecord[] {
    return this.users.filter(user => user.roles && user.roles.toLowerCase().includes(this.appRoles.ownerUser.toLowerCase()));
  }

  get searchedLocks(): UserLocksModel[] {
    return this.repo.searchedLocks;
  }

  formSubmit() {
    this.isSearch = true;
    const searchText = this.searchForm.controls['searchText'];
    if (searchText != null && searchText != undefined) {
      //this.getLocks(searchText.value.trim());
      const searchTextValue = searchText.value.trim();

      if (this.displayAssignedLocks && this.isGlobalAdmin) {
        this.filteredUserLocks = this.repo.assignedLocks.filter(lock =>
          (lock.serialNumber && lock.serialNumber.toLowerCase().includes(searchTextValue.toLowerCase()))
          || (lock.customer && lock.customer.toLowerCase().includes(searchTextValue.toLowerCase()))
          || (lock.location && lock.location.toLowerCase().includes(searchTextValue.toLowerCase()))
        );
      }
      else {
        this.filteredUserLocks = this.repo.locks.filter(lock =>
          (lock.serialNumber && lock.serialNumber.toLowerCase().includes(searchTextValue.toLowerCase()))
          || (lock.customer && lock.customer.toLowerCase().includes(searchTextValue.toLowerCase()))
          || (lock.location && lock.location.toLowerCase().includes(searchTextValue.toLowerCase()))
        );
      }

    } else {
      this.filteredUserLocks = [];
      this.clearSearchForm();
    }
  }

  /**
   * Get the active locks.
   * @param searchText Search string for the locks to be retrieved.
   */
  getLocks(searchText: string = null) {
    const lockReq: UserLocksModel = new UserLocksModel();
    lockReq.searchText = searchText;
    this.repo.getSearchedLocks(lockReq);
  }

  clearSearchForm() {
    this.isSearch = true;
    this.searchForm.reset();
    if (this.displayAssignedLocks && this.isGlobalAdmin) {
      this.repo.getAssignedLocks();
    } else {
      this.getLocks();
    }
  }

  activateLock() {
    const lockForm = this.activateLockForm;

    if (lockForm.valid) {
      this.repo.activateLock(lockForm.value).subscribe(activateLockRes => {
        this.spinner.hide();
        this.viewLocks();
        this.toastr.success('successfully Activated the Lock!', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
        this.closeActivateLockDialog();
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to activate lock', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  /** Activate multiple locks to a owner user. */
  activateMultiLocks() {
    const lockForm = this.activateMultiLocksForm;
    if (lockForm) {
      const lockType = lockForm.controls['lockType'];
      const lockTypeValue = this.appHelper.isNullOrUndefinedOrWhiteSpace(lockType.value) ? '' : lockType.value;
      lockForm.controls['lockType'].setValue(lockTypeValue);

      const countOfLocksToActivate = lockForm.controls['countOfLocksToActivate'].value;
      if (this.selectedLocks && this.selectedLocks.length >= AppConstants.Generic.minCountOfLocksToActivateByAdmin) {
        const locksToActivate = this.selectedLocks;
        const lockSerialNumbers = locksToActivate.map((lock) => lock.serialNumber);
        this.activateMultiLocksForm.controls['serialNumbers'].setValue(lockSerialNumbers);
      } else if (countOfLocksToActivate && countOfLocksToActivate >= AppConstants.Generic.minCountOfLocksToActivateByAdmin) {
        const locksToActivate = this.userLocks.slice(0, countOfLocksToActivate);
        const lockSerialNumbers = locksToActivate.map((lock) => lock.serialNumber);
        this.activateMultiLocksForm.controls['serialNumbers'].setValue(lockSerialNumbers);
      } else {
        this.toastr.error(`Count of locks to activate should be ${AppConstants.Generic.minCountOfLocksToActivateByAdmin} or more`, 'Warning', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
        return false;
      }

    }

    if (lockForm.valid) {
      this.repo.activateMultiLocks(lockForm.value).subscribe(response => {
        this.spinner.hide();
        this.viewLocks();
        if (response.statusCode == 200) {
          this.getAvailableLocks();
          this.spinner.hide();
          this.toastr.success('Successfully activated locks', '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
          this.clearSelectedLocks();
          this.closeActivateMultiLocksDialog();
        } else {
          this.spinner.hide();
          this.toastr.error(response.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to activate locks', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  cloneLock() {
    const cloneForm = this.cloneLockForm;

    if (cloneForm.valid) {
      const lockReq = new LockUpdateRequest();

      const lockSeedObj: UserLocksModel = cloneForm.controls['newSeedCode'].value;
      const lockSerialObj: UserLocksModel = cloneForm.controls['serialNumber'].value;

      //lockReq.newSeedCode = lockSeedObj.seedCode;
      lockReq.cloneLockSerialNumber = lockSeedObj.serialNumber;
      lockReq.serialNumber = lockSerialObj.serialNumber;

      this.repo.updateLock(lockReq).subscribe(cloneLockRes => {
        this.spinner.hide();
        if (!cloneLockRes.message) {
          this.toastr.error(`Failed to Clone the lock!`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else if (cloneLockRes.message && cloneLockRes.message.indexOf('Lock updated') > -1) {
          this.toastr.success('successfully Cloned the Lock!', '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
          this.viewLocks();
          this.closeCloneLockDialog();
        } else {
          this.toastr.error(cloneLockRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to clone the lock', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  changeSeedCode() {
    const changeSeedForm = this.changeSeedCodeForm;

    if (changeSeedForm.valid) {
      const lockReq = new LockUpdateRequest();

      const newSeedCode: string = changeSeedForm.controls['newSeedCode'].value;
      const lockSerialObj: UserLocksModel = changeSeedForm.controls['serialNumber'].value;

      lockReq.newSeedCode = newSeedCode;
      lockReq.serialNumber = lockSerialObj.serialNumber;

      // Using clone lock method for updating the seed code.
      this.repo.updateLock(lockReq).subscribe(cloneLockRes => {
        this.spinner.hide();
        if (!cloneLockRes.message) {
          this.toastr.error(`Failed to change the seed code!`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else if (cloneLockRes.message && cloneLockRes.message.indexOf('Lock updated') > -1) {
          this.toastr.success('successfully changed the seed code!', '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
          this.viewLocks();
          this.closeChangeSeedCodeDialog();
        } else {
          this.toastr.error(cloneLockRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to change the seed code!', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  buildOwnerUsersDropDown() {
    if (this.ownerUsers) {
      this.ownerUsersDropDown = [];
      this.ownerUsers.forEach((ownerUser) => {
        this.ownerUsersDropDown.push(
          { label: ownerUser.userName, value: ownerUser.id }
        );
      });
    }
  }

  buildLocksDropDown() {
    if (this.locksArray) {
      this.locksDropDown = [];
      this.locksArray.forEach((lock: UserLocksModel) => {
        if (lock && (this.isOwnerUser || this.isManager)) {
          this.locksDropDown.push(
            { label: lock.serialNumber, value: lock }
          );
        }

        if (lock && !this.disableGenerate && lock.serialNumber == this.cloneFromSelected) {
          this.cloneLockForm.controls['newSeedCode'].setValue(lock);
          this.cloneLockForm.controls['oldSeedCode'].setValue(lock.seedCode);
          this.cloneFromSelected = '';
          if (lock.seedCode != '0' && lock.seedCode.length > 1) {
            this.disableGenerate = true;
          }
        }

        this.locksFromDropdown.push(
          { label: lock.serialNumber, value: lock.serialNumber }
        );
        this.locksToDropdown.push(
          { label: lock.serialNumber, value: lock.serialNumber }
        );
      });
    }
  }

  closeActivateLockDialog() {
    this.showActivateLockDialog = false;
  }

  openActivateLockDialog() {
    this.activateLockForm.reset();
    this.showActivateLockDialog = true;
  }

  openDeActivateLockDialog() {
    this.deActivateLockForm.reset();
    this.showDeActivateLockDialog = true;
  }

  closeDeActivateLockDialog() {
    this.showDeActivateLockDialog = false;
  }

  openResendUserCodeDialog() {
    this.resendUserCodeForm.reset();
    this.showResendUserCodeDialog = true;
    this.buildUsersDropDown();
    this.buildLocksDropDown();
  }

  closeResendUserCodeDialog() {
    this.showResendUserCodeDialog = false;
  }

  closeActivateMultiLocksDialog() {
    this.showActivateMultiLocksDialog = false;
  }

  openActivateMultiLocksDialog() {
    this.buildLocksDropDown();
    this.activateMultiLocksForm.reset();

    if (this.selectedLocks && this.selectedLocks.length > 0) {
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].setValue(this.selectedLocks.length);
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].disable();
      this.activateMultiLocksForm.controls['fromLock'].disable();
      this.activateMultiLocksForm.controls['toLock'].disable();
      this.activateMultiLocksForm.controls['isCountOrRange'].disable();
    } else {
      this.clearSelectedLocks();
    }

    this.buildOwnerUsersDropDown();
    this.showActivateMultiLocksDialog = true;
  }

  clearSelectedLocks() {
    this.selectedLocks = [];
    this.activateMultiLocksForm.controls['countOfLocksToActivate'].reset();
    this.activateMultiLocksForm.controls['isCountOrRange'].enable();
    this.activateMultiLocksForm.controls['isCountOrRange'].setValue(this.constRange);
    this.onCountOrRangeRadioClick(this.constRange);
  }

  openCloneLockDialog() {
    this.msgs = [];
    this.cloneLockForm.reset();
    this.showCloneLockDialog = true;
    this.cloneFromSelected = '';
    this.disableGenerate = true;
  }

  openChangeSeedCodeDialog() {
    this.msgs = [];
    this.changeSeedCodeForm.reset();
    this.showChangeSeedCodeDialog = true;
  }

  closeCloneLockDialog() {
    this.msgs = [];
    this.showCloneLockDialog = false;
  }

  closeChangeSeedCodeDialog() {
    this.msgs = [];
    this.showChangeSeedCodeDialog = false;
  }

  onCloneFromChange(event) {
    const cloneForm = this.cloneLockForm;
    const lockSeedObj: UserLocksModel = cloneForm.controls['newSeedCode'].value;
    this.msgs = [];
    cloneForm.controls['oldSeedCode'].reset();

    if (lockSeedObj && lockSeedObj.seedCode && lockSeedObj.seedCode == '0') {
      this.msgs.push({ severity: 'error', summary: 'Attention!', detail: 'Selected lock does not have a seed code. Generate it and try cloning.' });
      this.disableCloning = true;
      return false;
    }
    this.disableCloning = false;

    const lockObj: UserLocksModel = event.value;
    if (event && lockObj && lockObj.seedCode) {
      cloneForm.controls['oldSeedCode'].setValue(lockObj.seedCode);
    } else {
      cloneForm.controls['oldSeedCode'].setValue('');
    }
    if (lockObj && lockObj.seedCode == '0') {
      this.disableGenerate = false;
    } else {
      this.disableGenerate = true;
    }
  }

  onLockSelectionChange(event) {
    const changeSeedCodeForm = this.changeSeedCodeForm;
    const lockObj: UserLocksModel = event.value;
    if (event && lockObj && lockObj.seedCode) {
      changeSeedCodeForm.controls['oldSeedCode'].setValue(lockObj.seedCode);
    }
  }

  generateRandomSeed() {
    const changeSeedCodeForm = this.changeSeedCodeForm;
    const selectedLock = changeSeedCodeForm.controls['serialNumber'].value;
    if (selectedLock && selectedLock.serialNumber) {
      const lockReq = new LockUpdateRequest();

      lockReq.serialNumber = selectedLock.serialNumber;
      lockReq.generateRandomSeedCode = 1;

      this.repo.updateLock(lockReq).subscribe(cloneLockRes => {
        this.spinner.hide();
        if (!cloneLockRes.message) {
          this.toastr.error(`Failed to Generate the Random SeedCode!`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else if (cloneLockRes.message && cloneLockRes.message.indexOf('Lock updated') > -1) {
          this.getLocks();
          this.toastr.success('Successfully Generated the Random SeedCode!', '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
          this.viewLocks();
        } else {
          this.toastr.error(cloneLockRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to Generate the Random SeedCode!', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Select lock to proceed', '', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  showGenerateDialog(userLock: UserLocksModel = null) {
    this.showGenerateCodeInputDialog = true;
    this.resetGenerateCodeInputForm();
    this.buildUsersDropDown();

    if (userLock) {
      let locks: UserLocksModel[] = [userLock];
      this.generateCodeInputForm.controls['lockSerialNumber'].setValue(locks);
    }
  }

  showRemoveCodeDialog(userLock: UserLocksModel = null) {
    // SHOW FLYOUT
    this.showRemoveCodeInputDialog = true;

    // SELECT LOCKS IN DROPDOWN
    let locks: UserLocksModel[] = [userLock];
    this.removeCodeInputForm.controls['lockSerialNumber'].setValue(locks);

    // POPULATE LOCKS DROPDOWN AND LOCK-USERS DROPDOWN
    this.activeLocksDropDown = [];
    this.activeLockUsersDropDown = [];
    this.repo.getActiveLockUsersAndLocks().subscribe((activeLockUsersAndLocks) => {
      this.spinner.hide();

      let activeLockSerialNumbers = [];
      activeLockUsersAndLocks.activeLocks.forEach((activeLock: ActiveLock) => { activeLockSerialNumbers.push(activeLock.lockSerialNumber) });
      this.activeLocksDropDown = this.locksDropDown.filter(lock => activeLockSerialNumbers.includes(lock.label));

      activeLockUsersAndLocks.activeLockUsers.forEach((activeLockUser: ActiveLockUser) => {
        this.activeLockUsersDropDown.push(
          { label: activeLockUser.lockUserName, value: activeLockUser.lockUserId }
        );
      });
    });
  }

  removeAccessCodes() {
    const form = this.removeCodeInputForm;
    if (form.valid) {
      const locks: UserLocksModel[] = form.controls['lockSerialNumber'].value;
      const lockUsers: LockUsers[] = form.controls['lockUser'].value ? form.controls['lockUser'].value : [];
      let rmCodesRequest: GenerateCode[] = [];

      locks.forEach((lock) => {
        lockUsers.forEach((lockUser) => {
          const lockUserReq: GenerateCode = {
            lockSerialNumber: lock.serialNumber,
            codeType: form.controls['codeType'].value.toLowerCase(),
            lockUserId: Number(lockUser),
            lockUserGroup: null
          };
          rmCodesRequest.push(lockUserReq);
        });
      });

      if (rmCodesRequest.length) {
        this.repo.removeCodes(rmCodesRequest).subscribe((lockUsersRes: any) => {
          this.spinner.hide();
          this.closeRemoveCodeInputDialog();
          if (!lockUsersRes.message) {
            this.appNotification.error('Failed to remove Access codes');
          } else {
            this.appNotification.success(lockUsersRes.message + '. Removed lock access codes.');
          }
        }, (err) => {
          this.spinner.hide();
          this.appNotification.error('Failed to remove Access codes');
        });
      } else {
        this.appNotification.error('Please Provide all the inputs', 'Input required');
      }
    }
    else {
      this.appNotification.error('Please Provide all the inputs', 'Input required');
      //this.appNotification.error('Something went wrong! Close the flyout and try again.', '');
    }
  }

  removeAccessCode() {
    const form = this.removeCodeInputForm;
    if (form.valid) {
      const lockUserReq = new GenerateCode();

      let lockUserId = 0;
      lockUserId = Number(form.controls['lockUser'].value.lockUserId);
      lockUserReq.lockSerialNumber = form.controls['lockSerialNumber'].value;
      lockUserReq.lockUserId = lockUserId;

      const codeType = form.controls['codeType'].value;
      if (codeType && codeType != '') {
        lockUserReq.codeType = codeType;
      }

      this.repo.removeCode(lockUserReq).subscribe((lockUsersRes: any) => {
        this.spinner.hide();
        this.closeRemoveCodeInputDialog();
        if (!lockUsersRes.message) {
          this.appNotification.error('Failed to remove Access codes');
        } else {
          this.appNotification.success(lockUsersRes.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.appNotification.error('Failed to remove Access codes');
      });
    } else {
      this.appNotification.error('Please Provide all the inputs', 'Input required');
    }
  }

  verifyAndGenerateAccessCode() {
    if (this.isConvertingToArbitraryUser) {
      this.showConfirmationSection = true;
    } else {
      this.showConfirmationSection = false;
      this.generateAccessCode();
    }
  }

  generateCode(genCodeRequest: GenerateCode[]): Observable<any> {
    if (genCodeRequest.length) {
      const messageType = this.generateCodeInputForm.controls['codeType'].value.toLowerCase() == AppConstants.LockCodeType.arbitrary.toLowerCase()
        ? AppConstants.Generic.messageTypeArbitraryCode
        : AppConstants.Generic.messageTypeCode;
      this.lockUsersDetailsWithCode = [];
      this.repo.generateCodes(genCodeRequest).subscribe((results: any[]) => {
        results.forEach(result => {
          if (result && result.keypadCode) {
            const lockUsersDetails: LockUsersDetails[] = [];

            if ((!result.lockUserId || result.lockUserId < 1)
              && result.lockUserGroup) {
              // BELOW CODES FETCHES THE USERS OF THE SELECTED GROUPS - MAY BE USED IN THE FUTURE - VC

              // this.repo.getLockUserGroupMems(result.lockUserGroup);
              // const lockUsers: LockUserGroupMems[] = this.repo.lockUserGroupMems;
              // lockUsers.forEach(lockUser => {
              //  const lockUserDetails: LockUsersDetails = {
              //    lockSerialNumber: generateInputForm.controls['lockSerialNumber'].value,
              //    serialNumber: generateInputForm.controls['lockSerialNumber'].value,
              //    codeTypeDescription: generateInputForm.controls['codeType'].value.toLowerCase(),
              //    lockUserGroup: result.lockUserGroup,
              //    lockUserName: lockUser.name,
              //    lockUserId: lockUser.id,
              //    keypadCode: result.keypadCode,
              //    lockLocation: null, added: null, deleted: null, disabled: null
              //  };
              //  lockUsersDetails.push(lockUserDetails);
              // });
            } else {
              this.lockUserIdAndAccessCode[result.lockUserName] = result.keypadCode;
              lockUsersDetails.push(result);
            }

            lockUsersDetails.forEach(lockUser => this.lockUsersDetailsWithCode.push(lockUser));
          } else if (result && (result == 'No more generated codes available')) {
            this.spinner.hide();
            this.appNotification.error(`Unable to assign code! ${result}.`);
            return false;
          }
        });
        this.closeGenerateCodeInputDialog();
        this.closeConfirmationSection();
        this.sendMessage(messageType);
        this.spinner.hide();
        this.appNotification.success('Successfully assigned access code.');
      }, (err) => { // TODO: handle error for individual requests
        this.spinner.hide();
        console.error('Error while assigning access code! ', err);
        if (err.error.text.toLowerCase() == 'no more generated codes available') {
          this.toastr.error(`${err.error.text}.`, 'Unable to assign code', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else {
          this.appNotification.error('Error! Something went wrong while assigning access code. Try again or contact administrator.');
        }
      });
    }
    return EMPTY;
  }

  generateAccessCode() {
    const generateInputForm = this.generateCodeInputForm;
    if (generateInputForm.valid) {
      const locks: UserLocksModel[] = generateInputForm.controls['lockSerialNumber'].value;
      let lockSerialNumbers = [];
      locks.forEach((lock) => lockSerialNumbers.push(lock.serialNumber));
      const lockUsers: LockUsers[] = generateInputForm.controls['lockUser'].value ? generateInputForm.controls['lockUser'].value : [];
      const lockUserGroupNames = String(generateInputForm.controls['lockUserGroupName'].value).split(AppConstants.SpecialChars.comma);
      this.messageMediumSelection = generateInputForm.controls['messageMedium'].value;
      const lockUserRequest: LockUsers[] = [];
      const genCodeRequest: GenerateCode[] = [];

      let eitherUserOrGroupSelected = (lockUsers.length + lockUserGroupNames.filter((group) => group != 'null').length) > 0;
      if (!eitherUserOrGroupSelected) {
        this.appNotification.error('Please select user(s) or group(s).', 'Input required');
      }

      lockUsers.forEach((lockUser) => {
        if (lockUser.id && lockUser.id > 0) {

          if (generateInputForm.controls['codeType'].value.toLowerCase() === AppConstants.LockCodeType.arbitrary.toLowerCase()
            && (!lockUser.arbitraryKeypadCode || lockUser.arbitraryKeypadCode < 1)) {
            const lockUserUpdateReq: LockUsers = {
              id: lockUser.id,
              name: lockUser.name,
              phone: lockUser.phone,
              email: lockUser.email,
              company: lockUser.company,
              updateArbitraryCode: 1,
              generateArbitraryCode: 0,
              arbitraryKeypadCode: lockUser.arbitraryKeypadCode,
              newName: null,
              countOfUsers: 1,
              addUsersStartingRange: 0
            };
            lockUserRequest.push(lockUserUpdateReq);
          }

          lockSerialNumbers.forEach((lockSerialNumber) => {
            const lockUserReq: GenerateCode = {
              lockSerialNumber: lockSerialNumber,
              codeType: generateInputForm.controls['codeType'].value.toLowerCase(),
              lockUserId: Number(lockUser.id),
              lockUserGroup: null
            };
            genCodeRequest.push(lockUserReq);
          });
        }
      });

      lockUserGroupNames.forEach((lockUserGroupName) => {
        lockSerialNumbers.forEach((lockSerialNumber) => {
          if (lockUserGroupName && lockUserGroupName !== 'null' && lockUserGroupName !== '') {
            const lockUserGroupReq: GenerateCode = {
              lockSerialNumber: lockSerialNumber,
              codeType: AppConstants.LockCodeType.arbitrary.toLowerCase(),
              lockUserGroup: lockUserGroupName,
              lockUserId: 0
            };
            genCodeRequest.push(lockUserGroupReq);
          }
        });
      });

      // Convert user to lock user and then assign/retrieve ARBITRARY Code to lock users
      if (lockUserRequest && lockUserRequest.length) {
        this.repo.updateLockUsers(lockUserRequest)
          .pipe(
            tap(response => console.log('UpdateLockUsers response :', response)),
            concatMap(() => this.generateCode(genCodeRequest)),
            tap(generateCodeRes => console.log('generateCode response :', generateCodeRes))
          )
          .subscribe(res => console.log(res));
      }
      else { // assign/retrieve ARBITRARY Code to lock users
        this.generateCode(genCodeRequest);
      }
    }
    else {
      this.appNotification.error('Please provide all the inputs.', 'Input required');
    }
  }

  closeRemoveCodeInputDialog() {
    this.showRemoveCodeInputDialog = false;
    this.removeCodeInputForm.reset();
  }

  closeGenerateCodeInputDialog() {
    this.showGenerateCodeInputDialog = false;
    this.resetGenerateCodeInputForm();
    this.getUpdatedLockUsersAndGroups();
  }

  sendMessage(messageType: string, messageMedium?: string) {
    const genCodeRes = this.lockUsersDetailsWithCode;
    if (!genCodeRes.length) {
      this.spinner.hide();
      return true;
    }

    const observables = [];
    if (this.messageMediumSelection && this.messageMediumSelection.length) {
      if (this.messageMediumSelection.includes(this.constPhone)) {
        messageMedium = this.constPhone;

        genCodeRes.forEach(userDetail => {
          if (userDetail) {
            const mesReq = new SendMessage();
            mesReq.lockUserId = userDetail.lockUserId;
            mesReq.medium = messageMedium;
            mesReq.messageType = messageType;
            if (messageType != AppConstants.Generic.messageTypeArbitraryCode) {
              mesReq.lockSerialNumber = userDetail.lockSerialNumber;
            }

            observables.push(this.repo.sendMessage(mesReq));
          }
        });
      }
      if (this.messageMediumSelection.includes(this.constEmail)) {
        messageMedium = this.constEmail;

        genCodeRes.forEach(userDetail => {
          if (userDetail) {
            const mesReq = new SendMessage();
            mesReq.lockUserId = userDetail.lockUserId;
            mesReq.medium = messageMedium;
            mesReq.messageType = messageType;
            if (messageType != AppConstants.Generic.messageTypeArbitraryCode) {
              mesReq.lockSerialNumber = userDetail.lockSerialNumber;
            }

            observables.push(this.repo.sendMessage(mesReq));
          }
        });
      }
    } else if (messageMedium && messageMedium != '') {
      genCodeRes.forEach(userDetail => {
        if (userDetail) {
          const mesReq: SendMessage = {
            lockUserId: userDetail.lockUserId,
            lockSerialNumber: userDetail.lockSerialNumber,
            medium: messageMedium,
            messageType: AppConstants.Generic.messageTypeCode
          };

          observables.push(this.repo.sendMessage(mesReq));
        }
      });
    }

    if (observables.length) {
      forkJoin(observables).subscribe(
        results => {
          this.spinner.hide();
          this.appNotification.success('Successfully sent message to the user(s).');
        },
        (err) => { // TODO: handle error for individual requests
          this.spinner.hide();
          this.appNotification.error('Error! Something went wrong while sending message to the user(s).');
        });
    } else {
      this.spinner.hide();
    }
  }

  onUserOrGroupRadioClick(value: string) {
    this.genCodeForUserOrGroup = value = value.toLowerCase();
    if (value === this.constUser.toLowerCase()) {
      this.generateCodeInputForm.controls['codeType'].reset();
      this.generateCodeInputForm.controls['codeType'].enable();
    } else {
      this.generateCodeInputForm.controls['codeType'].setValue(AppConstants.LockCodeType.arbitrary.toLowerCase());
      this.generateCodeInputForm.controls['codeType'].disable();
    }
    this.generateCodeInputForm.controls['lockUserId'].reset();
    this.generateCodeInputForm.controls['lockUser'].reset();
    this.generateCodeInputForm.controls['lockUserGroupName'].reset();

    this.enableOrDisableUserAndGroupControls();
    this.resetConvertingToArbitraryUserWarnings();
  }

  checkNonArbitraryUser() {
    this.msgs = [];
    this.isConvertingToArbitraryUser = false;
    const selectedCodeType = String(this.generateCodeInputForm.controls['codeType'].value);
    const selectedLockUsers: LockUsers[] = this.generateCodeInputForm.controls['lockUser'].value;
    const nonArbitraryUser = [];

    if (selectedLockUsers && selectedLockUsers.length) {
      selectedLockUsers.forEach((lockUser) => {
        if (lockUser.arbitraryKeypadCode < 1) {
          nonArbitraryUser.push(lockUser.name);
        }
      });
    }

    if (nonArbitraryUser.length > 0 && selectedCodeType.toLowerCase() == AppConstants.LockCodeType.arbitrary.toLowerCase()) {
      this.msgs.push({ severity: 'warn', summary: 'Warning!', detail: `Your selection includes temporary user(s) (${nonArbitraryUser.join(AppConstants.SpecialChars.commaWhitespace)}) who will be converted to the user and User code will be assigned.` });
      this.isConvertingToArbitraryUser = true;
    }
  }

  closeConfirmationSection() {
    this.showConfirmationSection = false;
  }

  resetConvertingToArbitraryUserWarnings() {
    this.isConvertingToArbitraryUser = false;
    this.msgs = [];
    this.closeConfirmationSection();
  }

  enableOrDisableUserAndGroupControls() {
    if (this.generateCodeInputForm
      && this.generateCodeInputForm.controls['lockUserId']
      && this.generateCodeInputForm.controls['lockUserGroupName']) {
      if (this.genCodeForUserOrGroup.toLowerCase() == this.constUser.toLowerCase()) {
        this.generateCodeInputForm.controls['lockUser'].enable();
        this.generateCodeInputForm.controls['messageMedium'].enable();
        this.generateCodeInputForm.controls['messageMedium'].reset();
        this.generateCodeInputForm.controls['lockUserGroupName'].disable();
      } else if (this.genCodeForUserOrGroup.toLowerCase() == this.constGroup.toLowerCase()) {
        this.generateCodeInputForm.controls['lockUser'].disable();
        this.generateCodeInputForm.controls['messageMedium'].disable();
        this.generateCodeInputForm.controls['messageMedium'].reset();
        this.generateCodeInputForm.controls['lockUserGroupName'].enable();
      }
    }
  }

  ShowEditLockDetails(userLock: UserLocksModel) {
    this.editLockDetailsForm.controls['serialNumber'].setValue(userLock.serialNumber);
    this.editLockDetailsForm.controls['location'].setValue(userLock.location);
    this.editLockDetailsForm.controls['customer'].setValue(userLock.customer);
    this.editLockDetailsForm.controls['notes'].setValue(userLock.notes);
    this.editLockDetailsForm.controls['Interface'].setValue(userLock.hasInterface);
    this.editLockDetailsForm.controls['LockType'].setValue(userLock.lockType);
    this.editLockDetailsForm.controls['status'].setValue(userLock.status);
    if (!this.appHelper.isNullOrUndefinedOrWhiteSpace(userLock.installationDate)) {
      this.editLockDetailsForm.controls['installationDate'].setValue(new Date(userLock.installationDate));
    }
    this.showEditLockDetailsDialog = true;
  }

  closeEditLockDetailsDialog() {
    this.showEditLockDetailsDialog = false;
    this.editLockDetailsForm.reset();
  }

  editLockDetails() {
    const form = this.editLockDetailsForm;
    if (form.valid) {


      const lockType = form.controls['LockType'];
      const lockTypeValue = this.appHelper.isNullOrUndefinedOrWhiteSpace(lockType.value) ? '' : lockType.value;
      form.controls['LockType'].setValue(lockTypeValue);

      const lockStatus = form.controls['status'];
      const lockStatusValue = this.appHelper.isNullOrUndefinedOrWhiteSpace(lockStatus.value) ? '' : lockStatus.value;
      form.controls['status'].setValue(lockStatusValue);

      const installationDate = form.controls['installationDate'];
      const installationDateValue = this.appHelper.isNullOrUndefinedOrWhiteSpace(installationDate.value) ? null : installationDate.value;
      form.controls['installationDate'].setValue(installationDateValue);

      this.repo.updateLock(form.value).subscribe((response) => {
        this.spinner.hide();
        if (!response.message) {
          this.appNotification.error('Something went wrong! Failed to update.');
        } else if (response.message && response.message.indexOf('Lock updated') > -1) {
          this.appNotification.success('Successfully updated!');
          this.viewLocks();
          this.closeEditLockDetailsDialog();
        } else {
          this.appNotification.error(response.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.appNotification.error('Something went wrong! Failed to update.');
      });
    } else {
      this.appNotification.error('Please Provide all the inputs', 'Input required');
    }
  }

  checkSeedCodeAvailability() {
    this.msgs = [];
    const form = this.changeSeedCodeForm;
    const lock: UserLocksModel = form.controls['serialNumber'].value;
    if (lock && lock.seedCode && lock.seedCode != '' && lock.seedCode != '0' && lock.seedCode.length > 1) {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: 'Warning!', detail: `Changing seed code will remove all previous data/codes from this lock. Are you sure you want to proceed?.` });
    }
  }

  deActivateLock() {
    const form = this.deActivateLockForm;
    if (form.valid) {
      const ownerUserId = form.controls['ownerUserId'].value;
      if (ownerUserId && ownerUserId > 0) {
        form.controls['ownerUserId'].setValue(ownerUserId);
      } else {
        this.appNotification.error('User name not found.', 'Invalid input');
        return false;
      }

      this.repo.deActivateLock(this.deActivateLockForm.value)
        .subscribe((response) => {
          this.spinner.hide();
          if (response.statusCode == 200) {
            this.appNotification.success(response.message);
            this.closeDeActivateLockDialog();
          } else {
            this.appNotification.error(response.message, 'Unable to deactivate lock');
          }
        });
    } else {
      this.appNotification.error('Please provide all inputs.', 'Input required');
    }
  }

  resendUserCode() {
    const form = this.resendUserCodeForm;
    if (form.valid) {
      this.lockUsersDetailsWithCode = [];
      const lockUserIds: any[] = form.controls['lockUser'].value ? form.controls['lockUser'].value : [];
      this.messageMediumSelection = form.controls['messageMedium'].value;

      lockUserIds.forEach((lockUserId) => {
        const lockUsersDetails = new LockUsersDetails();
        lockUsersDetails.lockUserId = lockUserId;
        this.lockUsersDetailsWithCode.push(lockUsersDetails);
      });
      this.sendMessage(AppConstants.Generic.messageTypeArbitraryCode);
      this.closeResendUserCodeDialog();
    } else {
      this.appNotification.error('Please provide all inputs.', 'Input required');
    }
  }

  onLocksFromDropdownChange() {
    const form = this.activateMultiLocksForm;
    form.controls['toLock'].reset();
    this.selectedLocks = [];
    this.activateMultiLocksForm.controls['countOfLocksToActivate'].reset();

    this.activateMultiLocksForm.controls['countOfLocksToActivate'].disable();

    if (form && form.controls['fromLock'].value && form.controls['fromLock'].value != '') {
      const selectedStartRangeLock = form.controls['fromLock'].value;
      this.locksToDropdown.splice(0, this.locksToDropdown.findIndex(lock => lock.value == selectedStartRangeLock));
      form.controls['toLock'].enable();
    } else {
      form.controls['toLock'].disable();
    }
  }

  onLocksToDropdownChange() {
    const form = this.activateMultiLocksForm;

    if (form
      && form.controls['fromLock'].value && form.controls['fromLock'].value != ''
      && form.controls['toLock'].value && form.controls['toLock'].value != ''
    ) {
      const selectedStartRangeLockId = form.controls['fromLock'].value;
      const selectedEndRangeLockId = form.controls['toLock'].value;
      const startRangeLockIndex = this.userLocks.findIndex(lock => lock.serialNumber == selectedStartRangeLockId);
      const endRangeLockIndex = this.userLocks.findIndex(lock => lock.serialNumber == selectedEndRangeLockId);
      this.selectedLocks = this.userLocks.slice(startRangeLockIndex, endRangeLockIndex + 1);
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].setValue(this.selectedLocks.length);
    } else {
      this.selectedLocks = [];
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].reset();
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].disable();
    }
  }

  onCountOrRangeRadioClick(value: string) {
    const form = this.activateMultiLocksForm;

    if (value === this.constCount) {
      form.controls['countOfLocksToActivate'].reset();
      form.controls['countOfLocksToActivate'].enable();
      form.controls['fromLock'].reset();
      form.controls['fromLock'].disable();
      form.controls['toLock'].reset();
      form.controls['toLock'].disable();
    } else {
      this.activateMultiLocksForm.controls['countOfLocksToActivate'].disable();
      form.controls['fromLock'].reset();
      form.controls['fromLock'].enable();
      form.controls['toLock'].reset();
      form.controls['toLock'].disable();
    }
  }

  getAvailableLocks() {
    this.repo.fetchUpdatedLocks().subscribe((locks: UserLocksModel[]) => {
      if (locks && locks.length > 0) {
        this.locksArray = locks;
        this.buildLocksDropDown();
      }
    });
  }

  /**
   * Toggles the element to show/hide the secret value.
   * @param secretElementId Id of the element containing the secret value.
   * @param toggleElementId Id of the element that toggles the visibility of secret value.
   * @param secret A string representation of the secret value.
   */
  toggleSecret(secretElementId, toggleElementId, secret) {
    this.appHelper.toggleSecretView(secretElementId, toggleElementId, secret);
  }
}
