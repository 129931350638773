import { Component, OnInit } from '@angular/core';
import * as AppConstants from '../common/app.constants';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
	/** Gets or sets the title for the Units page.*/
	pageTitle = AppConstants.PageTitle.helpPage;

	constructor() { }

	ngOnInit() {
	}
}
