export class LockGetRequest {
  constructor(
    public serialNumber: string = null,
    public ownerUserId: number = null,
    public location: string = null,
    public customer: string = null,
    public notes: string = null,
    public offset: number = null,
    public limit: number = null,
  ) { }
}
