import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppTimeZone {
  get currentTimeZoneOffset(): string {
    return this.getCurrentTimeZoneOffset();
  }

  get currentTimeZoneName(): string {
    return this.getCurrentTimeZoneName();
  }

  private getCurrentTimeZoneOffset(): string {
    return new Date().toString().match(/([-\+][0-9]+)\s/)[1];
  }

  private getCurrentTimeZoneName(): string {
    let currentTimeZoneName = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    currentTimeZoneName = currentTimeZoneName.toLowerCase() === 'british summer time'
      || currentTimeZoneName.toLowerCase() === 'gmt'
      ? 'GMT Standard Time'
      : currentTimeZoneName;
    return currentTimeZoneName;
  }

  //For "GMT-0400 (EDT)":
  //   new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1]

  //For "GMT-0400" :
  //  new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1]

  //For just "EDT" :
  //  new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1]

  //For just "-0400":
  //  new Date().toString().match(/([-\+][0-9]+)\s/)[1]
}

@Injectable({ providedIn: 'root' })
export class Helper {

  constSecretHidden = "*******";

  isNullOrUndefinedOrWhiteSpace(object: any) {
    return object === undefined || object === null || (typeof object === 'string' && String(object).trim() === '');
  }

  /**
   * Toggles the element to show/hide the secret value.
   * @param secretElementId Id of the element containing the secret value.
   * @param toggleElementId Id of the element that toggles the visibility of secret value.
   * @param secret A string representation of the secret value.
   */
  toggleSecretView(secretElementId, toggleElementId, secret) {
    if (!this.isNullOrUndefinedOrWhiteSpace(secretElementId) && !this.isNullOrUndefinedOrWhiteSpace(toggleElementId)) {
      const secretElement = document.getElementById(secretElementId);
      const toggleElement = document.getElementById(toggleElementId);

      if (!this.isNullOrUndefinedOrWhiteSpace(secretElement) && !this.isNullOrUndefinedOrWhiteSpace(toggleElement)) {
        if (secretElement.innerHTML.trim() === this.constSecretHidden && !this.isNullOrUndefinedOrWhiteSpace(secret)) {
          toggleElement.innerHTML = '<i class="pi pi-eye-slash" style="font-size: 1.5rem"></i>';
          secretElement.innerHTML = secret;
        } else {
          toggleElement.innerHTML = '<i class="pi pi-eye" style="font-size: 1.5rem"></i>';
          secretElement.innerHTML = this.constSecretHidden;
        }
      }
    }
  }
}
