import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { DataService } from './dataService';
import { NgxSpinnerService } from 'ngx-spinner';
import { Repository } from '../models/repository';
import { AppNotification } from '../Common/app.notification';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
	LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';

	constructor(private router: Router, private dataService: DataService,
		private spinner: NgxSpinnerService, private repo: Repository,
		private notify: AppNotification) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.spinner.hide();
		const isSessionValid: boolean = this.isValidSession();
		if (isSessionValid) {
			if (route.routeConfig.path == 'login') {
				this.router.navigate(['home']);
				return false;
			}

			return true;
		} else {
			if (route.routeConfig.path == 'login') {
				return true;
			} else {
				// not logged in so logout user and redirect to login page
				// this.commonSB.errorHandler("Session timedout");

				// return false & uncomment logout after fixing login issue.
				this.logout(state);
				return false;
				// return true;
			}
		}
	}

	isValidSession(): boolean {
		const accessToken = this.getAccessToken();
		const tokenInfo = this.getDecodedAccessToken(accessToken);
		if (tokenInfo != null && tokenInfo != undefined) {
			const current_time = Date.now() / 1000; // time in seconds
			const expiryDate = tokenInfo.exp;
			const timeDiffMins = (expiryDate - current_time) / 60;
			if ((expiryDate > current_time)) {
				// if (timeDiffMins >= 0 && timeDiffMins < 16) {
				//     console.log("time diff::" + timeDiffMins + " mins..");
				//     this.refreshToken().subscribe((res) => {
				//         let tokenInfo = res;
				//         this.setToken(tokenInfo != undefined ? tokenInfo.token : '');
				//     });
				//     return true;
				// } else {
				//     console.log("time diff::" + timeDiffMins + " mins..");
				//     return true;
				// }
				return true;
			} else {
				console.log('Token expired...');
				this.notify.error('Login again to continue.', 'Session expired!');
				return false;
			}
		} else {
			return false;
		}
	}

	logout(state) {
		// localStorage.removeItem(this.LOCALSTORAGE_AUTH_TOKEN);

		// this.router.navigate(["login"]);
		this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
		this.repo.logout();
	}

	getAccessToken() {
		return localStorage.getItem(this.LOCALSTORAGE_AUTH_TOKEN);
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
}
