export class UserUpdateRequest {
	constructor(
		public userName: string = '',
		public firstName: string = '',
		public lastName: string = '',
		public phone: string = '',
		public email: string = '',
		public company: string = '',
		public password: string = '',
		public generateNewActivationCode: number = 0,
		public maxArbitraryCodes: number = null,
		public maxPerpetualCodes: number = null,
		public maxDailyCodes: number = null,
		public maxWeeklyCodes: number = null,
		public globalAdminBlock: number = 0
	) { }
}
