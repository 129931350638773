export class LockUserGroup {
	constructor(
		public lockSerialNumber: string = '',
		public lockUserId: number = null,
		public lockUserGroupName: string = '',

		public groupName: string = '',
		public created: string = ''
	) { }
}
