import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ModelModule } from './models/model.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { Login } from './login/login.component';
import { AppFooterComponent } from './footer/app-footer.component';
import { DataService } from './shared/dataService';
import { User } from './users/user.component';
import { AuthenticatedGuard } from './shared/authenticated.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptorService } from './interceptors/token.interceptor.service';
import { UnitsComponent } from './units/units.component';
import { AppPrimeNGModule } from './app-primeng/app-primeng.module';
import { GroupsComponent } from './groups/groups.component';
import { AuditsComponent } from './audits/audits.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HelpComponent } from './help/help.component';

@NgModule({
	declarations: [
		AppComponent,
		NavMenuComponent,
		HomeComponent,
		UnitsComponent,
		GroupsComponent,
		AuditsComponent,
		AppFooterComponent,
		Login,
		User,
		UserProfileComponent,
		HelpComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		NgxSpinnerModule,
		AppPrimeNGModule,
		ToastrModule.forRoot(),
		RouterModule.forRoot([
			{ path: '', redirectTo: '/home', pathMatch: 'full', canActivate: [AuthenticatedGuard] },
			{ path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthenticatedGuard] },
			{ path: 'units', component: UnitsComponent, canActivate: [AuthenticatedGuard] },
			{ path: 'login', component: Login, canActivate: [AuthenticatedGuard] },
			{ path: 'user', component: User, canActivate: [AuthenticatedGuard] },
			{ path: 'groups', component: GroupsComponent, canActivate: [AuthenticatedGuard] },
			{ path: 'audits', component: AuditsComponent, canActivate: [AuthenticatedGuard] },
			{ path: 'user-profile', component: UserProfileComponent, canActivate: [AuthenticatedGuard] },
			{ path: 'help', component: HelpComponent, canActivate: [AuthenticatedGuard] }
		]),
		ModelModule
	],
	providers: [DataService, AuthenticatedGuard, {
		provide: HTTP_INTERCEPTORS,
		useClass: TokenInterceptorService,
		multi: true
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }
