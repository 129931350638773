import { Component, HostListener, AfterViewInit, OnInit } from '@angular/core';
import { DataService } from '../shared/dataService';
import { Router } from '@angular/router';
import { Repository } from '../models/repository';
import * as $ from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LockUserGroup } from '../models/LockUserGroup.model';
import { LockUserGroupMems } from '../models/LockUserGroupMems.model';
import { LockUsers } from '../models/LockUsers.model';
import * as AppConstants from '../common/app.constants';
import { AddUserToGroupRequest } from '../models/AddUserToGroupRequest.model';
import * as AppHelper from '../common/app.helper';

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, AfterViewInit {
  tblDheight = AppConstants.Generic.tableHeight;
  userRole: string;

  id = '';
  required = '';
  submitted = '';
  cols: any[];
  cols1: any[];
  cols2: any[];

  /** Gets or sets the title for the Units page.*/
  pageTitle = AppConstants.PageTitle.groups;

  showCreateGroupDialog = false;
  showDeleteGroupDialog = false;

  constSecretHidden = this.appHelper.constSecretHidden;

  showAddUserToGroupDialog = false;
  showDeleteFromGroupDialog = false;

  lockUserGroupForm: FormGroup;
  lockUserDeleteGroupForm: FormGroup;

  deleteFromGroupForm: FormGroup;
  addToGroupForm: FormGroup;

  lockUserGroupCols: any[];
  lockUserGroupMemsCols: any[];

  viewLockUserGroups = false;

  selectedLockUserGroup: LockUserGroup;

  lockUsersArray: Array<LockUsers> = this.repo.lockUsers;
  groupsArray: Array<LockUserGroup> = this.repo.lockUserGroups;

  lockUsersDropDown: Array<any> = [];

  groupsDropDown: Array<any> = [];

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.tblDheight = $(window).height() - 260 + 'px';
  }

  constructor(private data: DataService, private router: Router, private repo: Repository,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private appLabels: AppConstants.AppLabels, private apptimeZone: AppHelper.AppTimeZone,
    private appHelper: AppHelper.Helper) {
    this.tblDheight = $(window).height() - 260 + 'px';
    this.userRole = data.getLoginUserRole();

    this.lockUserGroupForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    });

    this.lockUserDeleteGroupForm = this.formBuilder.group({
      groupName: ['', Validators.required]
    });

    this.addToGroupForm = this.formBuilder.group({
      lockUsername: [''],
      lockUserId: ['', Validators.required],
      lockUserGroupName: ['', Validators.required]
    });

    this.deleteFromGroupForm = this.formBuilder.group({
      lockUserId: ['', Validators.required],
      groupName: ['', Validators.required]
    });

    this.viewLockUserGroups = false;

    this.lockUserGroupMemsCols = [
      { field: 'name', header: appLabels.user },
      { field: 'phone', header: appLabels.phone },
      { field: 'email', header: appLabels.email },
      { field: 'arbitraryKeypadCode', header: appLabels.arbitraryCode },
      { field: 'created', header: appLabels.dateCreated }
    ];

    this.lockUserGroupCols = [
      { field: 'groupName', header: appLabels.groupName },
      { field: 'created', header: appLabels.dateCreated }
    ];

    if (this.lockUsersArray && this.lockUsersArray.length > 0) {
      this.buildUsersDropDown();
    }
  }
  ngOnInit(): void {
    this.repo.getUpdatedLockUsers().subscribe((lockUsers: LockUsers[]) => {
      if (lockUsers && lockUsers.length > 0) {
        console.log('inside lockusers observable...');
        this.lockUsersArray = lockUsers;
        this.buildUsersDropDown();
      }
    });

    this.repo.getUpdatedLockUserGroups().subscribe((lockUserGroups: LockUserGroup[]) => {
      if (lockUserGroups && lockUserGroups.length > 0) {
        console.log('inside lockUserGroups observable...');
        this.groupsArray = lockUserGroups;
        this.buildGroupsDropDown();
      }
    });
  }
  ngAfterViewInit(): void {
    if (this.lockUserGroups.length == 0) {
      this.manageGridDetails();
    }
  }

  get currentTimeZoneOffset(): string {
    return this.apptimeZone.currentTimeZoneOffset;
  }

  get currentTimeZoneName(): string {
    return this.apptimeZone.currentTimeZoneName;
  }

  get lockUserGroups(): LockUserGroup[] {
    return this.repo.lockUserGroups;
  }

  get lockUserGroupMems(): LockUserGroupMems[] {
    return this.repo.lockUserGroupMems;
  }

  viewSelLocksUserGroupMems(groupDetails: LockUserGroup) {
    this.pageTitle = `Users of Group : ${groupDetails.groupName}`;
    this.viewLockUserGroups = true;
    this.repo.getLockUserGroupMems(groupDetails);
    this.selectedLockUserGroup = groupDetails;
  }

  backToGroups() {
    this.pageTitle = AppConstants.PageTitle.groups;
    this.viewLockUserGroups = false;
  }

  showCreateGroup() {
    this.lockUserGroupForm.reset();
    this.showCreateGroupDialog = true;
  }

  showDeleteGroup() {
    this.lockUserDeleteGroupForm.reset();
    this.showDeleteGroupDialog = true;
  }

  showAddToGroup() {
    this.addToGroupForm.reset();
    this.showAddUserToGroupDialog = true;
  }

  showDeleteFromGroup() {
    this.deleteFromGroupForm.reset();
    this.showDeleteFromGroupDialog = true;
  }

  closeCreateGroupDialog() {
    this.showCreateGroupDialog = false;
  }

  closeDeleteGroupDialog() {
    this.showDeleteGroupDialog = false;
  }

  closeAddToGroupDialog() {
    this.showAddUserToGroupDialog = false;
  }

  closeRemoveFromGroupDialog() {
    this.showDeleteFromGroupDialog = false;
  }

  manageGridDetails() {
    this.repo.readLockUserGroups();
  }

  buildUsersDropDown() {
    if (this.lockUsersArray) {
      this.lockUsersDropDown = [];
      this.lockUsersArray.forEach((lockUser: LockUsers) => {
        if (lockUser.arbitraryKeypadCode && lockUser.arbitraryKeypadCode > 0) {
          this.lockUsersDropDown.push(
            { label: lockUser.name + " " + this.appLabels.arbitraryUserInidicator, value: lockUser.id }
          );
        }
      });
    }
  }

  buildGroupsDropDown() {
    if (this.groupsArray) {
      this.groupsDropDown = [];
      this.groupsArray.forEach((lockUserGroup: LockUserGroup) => {
        this.groupsDropDown.push(
          { label: lockUserGroup.groupName, value: lockUserGroup.groupName }
        );
      });
    }
  }

  createLockUserGroup() {
    const form = this.lockUserGroupForm;
    if (form.valid) {
      this.repo.createLockUserGroup(form.value).subscribe(createGroupRes => {
        this.repo.readLockUserGroups();
        this.spinner.hide();
        this.toastr.success('successfully created lock user group!', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
        this.closeCreateGroupDialog();
      }, (err) => {
        this.spinner.hide();
        this.closeCreateGroupDialog();
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  deleteLockUserGroup() {
    const form = this.lockUserDeleteGroupForm;
    if (form.valid) {
      this.repo.deleteLockUserGroup(form.value).subscribe(deleteGroupRes => {
        this.spinner.hide();
        if (!deleteGroupRes.message) {
          this.toastr.error(`Failed to delete lock user group`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else if (deleteGroupRes.message && deleteGroupRes.message.indexOf('does not') > -1) {
          this.toastr.error(deleteGroupRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else {
          this.repo.readLockUserGroups();
          this.toastr.success(deleteGroupRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
        this.closeDeleteGroupDialog();
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to delete lock user group', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
        this.closeDeleteGroupDialog();
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  addUserToGroup() {
    const addGroupForm = this.addToGroupForm;
    if (addGroupForm.valid) {
      const lockUserGroupReq = new AddUserToGroupRequest();
      lockUserGroupReq.lockUserIds = addGroupForm.controls['lockUserId'].value;
      lockUserGroupReq.lockUserGroupName = addGroupForm.controls['lockUserGroupName'].value;

      this.repo.addLockUserToGroup(lockUserGroupReq).subscribe((addGroupRes: any) => {
        this.spinner.hide();
        this.closeAddToGroupDialog();
        if (!addGroupRes.message) {
          this.toastr.error(`Failed to add Arbitary lock user to Group ${lockUserGroupReq.lockUserGroupName}`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else if (addGroupRes.message && addGroupRes.message.indexOf('does not') > -1) {
          this.toastr.error(addGroupRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 4000
          });
        } else {
          this.toastr.success('User(s) added to group.', 'Success', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(`Failed to add Arbitary lock user to Group ${lockUserGroupReq.lockUserGroupName}`, '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  deleteUserFromGroups() {
    const removeGroupForm = this.deleteFromGroupForm;
    if (removeGroupForm.valid) {
      const lockUserGroupReq = new LockUserGroup();
      lockUserGroupReq.lockUserId = Number(removeGroupForm.controls['lockUserId'].value);
      lockUserGroupReq.groupName = removeGroupForm.controls['groupName'].value;

      this.repo.deleteLockUserFromGroup(lockUserGroupReq).subscribe((deleteGroupRes: any) => {
        this.spinner.hide();
        this.closeRemoveFromGroupDialog();
        if (!deleteGroupRes.message) {
          this.toastr.error(`Failed to delete lock user from Groups`, '', {
            positionClass: 'toast-top-center',
            timeOut: 3000
          });
        } else {
          this.toastr.success(deleteGroupRes.message, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(`Failed to delete lock user from Groups`, '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', 'Input required', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  /**
   * Toggles the element to show/hide the secret value.
   * @param secretElementId Id of the element containing the secret value.
   * @param toggleElementId Id of the element that toggles the visibility of secret value.
   * @param secret A string representation of the secret value.
   */
  toggleSecret(secretElementId, toggleElementId, secret) {
    this.appHelper.toggleSecretView(secretElementId, toggleElementId, secret);
  }
}
