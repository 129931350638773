import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { Router } from '@angular/router';

const resetInitiateURL = '/api/passwordReset/initiate';
const resetPasswordURL = '/api/passwordReset/reset';
const updateUserPasswordURL = '/api/user/UpdateUserPassword';
const getUserURL = '/api/user/GetUserDetails';

@Injectable()
export class DataService {
  LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';
  public userData: UserData;
  private httpClient: HttpClient;

  private token = '';
  private tokenExpiration: Date = new Date();
  loggedInAsOwner = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, handler: HttpBackend,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private router: Router) {
    this.httpClient = new HttpClient(handler);
  }

  loginAsOwner(adminAsOwner: boolean) {
    this.loggedInAsOwner.next(adminAsOwner);
  }

  checkIfAdminLoggedAsOwner(): Observable<boolean> {
    return this.loggedInAsOwner.asObservable();
  }

  public get loginRequired(): boolean {
    return this.token.length == 0 || this.tokenExpiration > new Date();
  }

  public getLock() {
    return this.http.get('/api/lock', { withCredentials: true })
      .pipe(
        map((response: any) => {
          console.log('Success');
          return true;
        }));
  }

  public login(creds) {
    return this.httpClient.post('/account/createtoken', creds)
      .pipe(
        map((response: any) => {
          const tokenInfo = response;
          if (tokenInfo && tokenInfo.token) {
            window.localStorage.setItem('authenticationToken', tokenInfo.token);
            return true;
          } else {
            return false;
          }
        }),
        catchError((err: any) => {
          this.spinner.hide();
          return throwError(err);
        })
      );
  }

  fetchLoggedInUserDetails(payload) {
    this.spinner.show();
    return this.http.post<UserGetResponseRecord>(`${getUserURL}`, payload);
  }

  generatePasswordResetLink(payload) {
    this.spinner.show();
    return this.httpClient.post<any>(`${resetInitiateURL}`, payload);
  }

  resetPassword(payload) {
    this.spinner.show();
    return this.httpClient.post<any>(`${resetPasswordURL}`, payload);
  }

  updatePassword(request) {
    this.spinner.show();
    return this.http.post<any>(`${updateUserPasswordURL}`, request);
  }

  setAccessToken(token) {
    window.localStorage.setItem('authenticationToken', token);
  }

  getAccessToken() {
    return localStorage.getItem(this.LOCALSTORAGE_AUTH_TOKEN);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  getLoginUserRole() {
    let role = 'User';
    const token = this.getAccessToken();
    const decodedToken = this.getDecodedAccessToken(token);
    if (decodedToken) {
      role = decodedToken.Role;
    }
    return role;
  }
}

export interface UserData {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  roles: string;
}
