import { Injectable } from '@angular/core';

/** Gets constants for various roles. */
@Injectable({
	providedIn: 'root'
})
export class AppRoles {
	lockUser = 'LockUser';
	user = 'User';
	auditCollector = 'AuditCollector';
	ownerUser = 'OwnerUser';
	globalAdmin = 'GlobalAdmin';

	lockUserLabel = 'Lock User';
	userLabel = 'Manager';
	auditCollectorLabel = 'Audit Collector';
	ownerUserLabel = 'Owner User';
	globalAdminLabel = 'Global Admin';
}
