import { Component } from '@angular/core';
import { Repository } from './models/repository';
import { UserLocksModel } from './models/UserLocksModel.model';
import { DataService } from './shared/dataService';
import { UserGetRequest } from './models/UserGetRequest.model';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {
	title = 'app';
	hideMenu = true;

	constructor(private dataService: DataService, private repo: Repository) {
		let userRole = '';
		const token = dataService.getAccessToken();
		const decodedToken = dataService.getDecodedAccessToken(token);

		if (decodedToken) {
			userRole = decodedToken.Role;
		}

		if (userRole && userRole != 'GlobalAdmin') {
			if (decodedToken && this.repo.lockUserGroups && this.repo.lockUserGroups.length == 0) {
				this.repo.readLockUserGroups();
			}
			if (decodedToken && this.repo.lockUsers && this.repo.lockUsers.length == 0) {
				this.repo.getLockUsers(new UserLocksModel());
			}
		}

		if (decodedToken && this.repo.users && this.repo.users.length == 0) {
			const userRequest: UserGetRequest = { userName: decodedToken.unique_name };
			this.repo.getUser(userRequest);
		}
	}

	ngOnInit() {
		const context = this;
		window.addEventListener('beforeunload', function (e) {
			context.logout();
		});
	}

	toggleMenu() {
		this.hideMenu = !this.hideMenu;
	}

	logout() {
		this.repo.logout();
	}
}
