import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoginRequest } from '../models/LoginRequest.model';
import { Repository } from '../models/repository';
import { UserGetResponseRecord } from '../models/userGetResponseRecord.model';
import { DataService } from '../shared/dataService';
import { AppLabels } from '../Common/app.constants';
import { AppRoles } from '../common/app.roles';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  hideMenu = true;
  hideLogout = true;
  isValidSession = false;
  LOCALSTORAGE_AUTH_TOKEN = 'authenticationToken';

  subscriptions: Array<Subscription> = [];

  addUserForm: FormGroup;

  showLoginOwner = false;
  loginUser: string;
  userRole: string;

  usersArray: Array<UserGetResponseRecord> = this.repo.users;

  usersDropDown: Array<any> = [];

  constructor(private router: Router, private dataService: DataService,
    private formBuilder: FormBuilder, private repo: Repository,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private appLabels: AppLabels, private roles: AppRoles) {
    this.setUserInfo();

    this.addUserForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      option: ['', Validators.required]
    });

    this.repo.getUpdatedUsers().subscribe((users: UserGetResponseRecord[]) => {
      if (users && users.length > 0) {
        this.usersArray = users;
        this.buildUsersDropDown();
      }
    });
  }

  ngOnInit() {
    const session = localStorage.getItem('authenticationToken');
    if (session) {
      this.isValidSession = true;
    } else {
      this.isValidSession = false;
    }
    this.subscriptions.push(
      this.dataService.checkIfAdminLoggedAsOwner().subscribe((isAdminAsOwner: boolean) => {
        if (isAdminAsOwner) {
          this.setUserInfo();
        }
      })
    );
  }

  setUserInfo() {
    const token = this.dataService.getAccessToken();
    const decodedToken = this.dataService.getDecodedAccessToken(token);
    if (decodedToken) {
      this.loginUser = decodedToken.unique_name;
      this.userRole = decodedToken.Role;
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggleMenu() {
    this.hideMenu = !this.hideMenu;
  }

  toggleLogout() {
    this.hideLogout = !this.hideLogout;
  }

  navHome() {
    this.router.navigate(['home']);
  }

  navHelp() {
    this.router.navigate(['help']);
  }

  navUser() {
    this.router.navigate(['user']);
  }

  navUnits() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['units']));
  }

  navAssignedUnits() {
    const navExtras: NavigationExtras = {
      queryParams: {
        displayAssignedLocks: true
      }
    };
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['units'], {
        state: { locks: navExtras }
      }));
  }

  navCodes() {
    this.router.navigate(['codes']);
  }

  navGroups() {
    this.router.navigate(['groups']);
  }

  navAudits() {
    this.router.navigate(['audits']);
  }

  navUserProfile() {
    this.router.navigate(['user-profile']);
  }

  showLoginOwnerDialog() {
    this.showLoginOwner = true;
  }

  closeLoginUserDialog() {
    this.showLoginOwner = false;
  }

  loginAsOwner(userInfoForm: FormGroup) {
    if (this.addUserForm.valid) {
      const userLoginReq = new LoginRequest();
      Object.assign(userLoginReq, userInfoForm.value);
      this.spinner.show();
      this.dataService.login(userLoginReq).subscribe((status: any) => {
        this.spinner.hide();
        if (status) {
          // Clearing Users, LockUsers and LockUserGroups data
          this.repo.clearUserNGroupsData();

          this.repo.loadUsersGroups();
          this.closeLoginUserDialog();
          this.toastr.success(`Successfully logged in as Owner User: ${userLoginReq.option}`, '', {
            positionClass: 'toast-top-center',
            timeOut: 5000
          });

          // Re-render the home page as Admin logged in as Owner
          this.dataService.loginAsOwner(true);
          this.navHome();
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error('Failed to Login As Owner', '', {
          positionClass: 'toast-top-center',
          timeOut: 3000
        });
      });
    } else {
      this.toastr.error('Please Provide all the inputs', '', {
        positionClass: 'toast-top-center',
        timeOut: 3000
      });
    }
  }

  logout() {
    this.repo.logout();
  }

  buildUsersDropDown() {
    if (this.usersArray) {
      this.usersArray.sort((a, b) => a.userName.localeCompare(b.userName));
      this.usersDropDown = [];
      this.usersDropDown.push(
        { label: 'Select', value: null }
      );
      this.usersArray.forEach((lockUserGroup: UserGetResponseRecord) => {
        if (this.userRole == this.roles.globalAdmin && lockUserGroup.roles
              && lockUserGroup.roles.includes(this.roles.ownerUser))
        {
          this.usersDropDown.push(
            { label: lockUserGroup.userName, value: lockUserGroup.userName }
          );
        } else if (this.userRole == this.roles.ownerUser && lockUserGroup.roles && lockUserGroup.roles == this.roles.user) {
          this.usersDropDown.push(
            { label: lockUserGroup.userName, value: lockUserGroup.userName }
          );
        } else if (this.userRole == this.roles.user && lockUserGroup.roles && lockUserGroup.roles == this.roles.auditCollector) {
          this.usersDropDown.push(
            { label: lockUserGroup.userName, value: lockUserGroup.userName }
          );
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
